import {LanguageProxy} from "@me-team/host/main/graphql/types";
import {ReactSelectInterface} from "../../interfaces/interfaces";

class LanguagesService {
    public static languageFilter = (languagesList: LanguageProxy[]) : ReactSelectInterface[] => {
        return languagesList.map((language : LanguageProxy ) : ReactSelectInterface  => ({
            value: language.id,
            label: language.name
        })) || []
    }
}

export default LanguagesService