import React, { useCallback, useState } from 'react';
import DateView from '@services/ui-components/src/DatePicker/DatePicker';
import { ErrorMessage, useFormikContext } from 'formik';
import {DateTime} from 'luxon';

interface FormValues {
  dateOfBirth: string;
}

type DatePicker = {
  label: string;
  name: string;
  placeholder: string;
  value?: string;
  id: string;
  disabled?: boolean;
};

const DatePicker: React.FC<DatePicker> = ({
                                            label,
                                            name,
                                            placeholder,
                                            value,
                                            id,
                                            disabled,
                                            ...rest
                                          }) => {
  const initialDate = value ? DateTime.fromISO(value).toJSDate() : null;
  const [startDate, setStartDate] = useState<Date | null>(initialDate);
  const { setFieldValue } = useFormikContext<FormValues>();

  const handleDateChange = useCallback(
    (value: Date | null) => {
      const submitFormat = 'yyyy-MM-dd';
      const formattedDate = value ? DateTime.fromJSDate(value).toFormat(submitFormat) : '';
      setFieldValue(name, formattedDate);
      setStartDate(value);
    },
    [setFieldValue, name]
  );
  return (
    <>
      <div className='col-md-2 mb-2 mb-md-0 d-flex align-items-center'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold'>
          {label}
        </label>
      </div>
      <div className='col-md-10 '>
        <div className='position-relative'>
          <DateView
            id={id}
            {...rest}
            className='form-control  w-100'
            placeholderText={placeholder}
            selected={startDate}
            onChange={handleDateChange}
            maxDate={new Date()}
            wrapperClassName='w-100 position-relative'
            showYearDropdown
            yearDropdownItemNumber={104}
            scrollableYearDropdown
            dateFormat='dd.MM.yyyy'
            disabled={disabled}
          />
          <i
            className='bi bi-calendar4-week position-absolute top-0 end-0 pe-2 bottom-0 pt-3'
            style={{ pointerEvents: 'none' }}
          ></i>
        </div>

        <ErrorMessage name={name}>{msg => <div
          className='invalid-feedback'>{msg}</div>}</ErrorMessage>
      </div>
    </>
  );
};

export default DatePicker;
