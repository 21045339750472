 import React, {useEffect, useRef, useState} from 'react';
import {ErrorMessage, Form, Formik} from 'formik';
import {Button, Col, Row} from 'react-bootstrap';
import {
  CheckboxSelect,
  ModalDelete,
  PhonePicker,
  ReactAsyncSelect,
  ReactSelect,
  Spinner,
  TextInput,
} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {
  cityOptionsList,
  countryOptionsList,
  currencyOptionsList,
  languagesMenuOptionsList,
  timezoneOptionsList,
  updateCompanyFormValidationSchema
} from './constants';
import {formatTimeZone} from '@me-team/host/src/utils/utils';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import ImageCrop from "@services/ui-components/src/ImageCrop/ImageCrop";
import {UserRole} from '../../utils/enums';
import {PhoneInputRefType} from 'react-international-phone';
import {
  useGetInfoCompanyQuery,
  useGetRelationsQuery,
  useGetRoleUserQuery, useUpdateCompanyMutation
} from '@me-team/host/main/company/graphql/company.hooks';
import { CompanyInput } from '@me-team/host/main/graphql/types';
 import LanguagesService from "../../services/LanguagesService/LanguagesService";
 import {useGetCitiesQuery} from '@me-team/host/main/branches/graphql/branches.hooks';

type SetFieldValueType = (field: string, value: string | boolean | string[] | File | (string | File)[]) => void;

interface Option {
  value: any;
  label: string;
}

interface UpdateCompanyProps {
  setShowModalDelete: (showModalDelete: boolean) => void,
  showModalDelete: boolean
}

const UpdateCompanyForm: React.FC<UpdateCompanyProps> = ({setShowModalDelete, showModalDelete}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const basePath = process.env.REACT_APP_POS_URL;
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const phonePickerRef = useRef<PhoneInputRefType>(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [isMenuModule, setIsMenuModule] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [selectedMessageLanguage, setSelectedMessageLanguage] = useState(null);
  const [selectedLanguagesMenu, setSelectedLanguagesMenu] = useState(null);
  const [selectedMainLanguage, setSelectedMainLanguage] = useState(null);
  const [showRecoveryModal, setShowRecoveryModal] = useState(false);
  const {data: infoUserData} = useGetRoleUserQuery({
    variables: { companyId: +currentCompanyId },
    skip: !currentCompanyId,
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const role = infoUserData?.user?.role?.id

  const handleRecoveryModalClose = () => setShowRecoveryModal(false);

  const isOtherCity = selectedCity?.value === -1

  const {
    data: infoCompanyData,
    loading: loadingCompany,
    error: errorCompany,
    refetch: refetchInfoCompany
  } = useGetInfoCompanyQuery({
    variables: { companyId: +currentCompanyId },
    skip: !currentCompanyId,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const infoCompany = infoCompanyData && infoCompanyData?.user?.company

  const {
    data: relationsData,
    loading: loadingRelations,
  } = useGetRelationsQuery({
    context: {
      errorType: 'global'
    },
    variables: {isMessageOnly : true},
    skip: !currentCompanyId,
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const {data: cityData, loading: loadingCities} = useGetCitiesQuery({
    variables: {
      countryId: selectedCountry?.value && selectedCountry?.value
    },
    skip: !selectedCountry?.value,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const [updateCompany] = useUpdateCompanyMutation()

  const handleModalDeleteClose = () => setShowModalDelete(false);
  const handleRecoveryCansel = () => {
    setShowRecoveryModal(false);
    window.location.href = `${basePath}/branch/company/${currentCompanyId}/${currentBranchId}`
  }

  const createInput = (isDeleted: boolean) => ({
    name: infoCompany?.name,
    country: infoCompany?.country?.id,
    city: infoCompany?.city?.id,
    address: infoCompany?.address,
    phone: infoCompany?.phone,
    website: infoCompany?.website ? infoCompany?.website : null,
    timezone: infoCompany?.timeZone?.id,
    messageLanguage: infoCompany?.messageLanguage?.id,
    currency: infoCompany?.currency?.id,
    isUpdateLogo: true,
    deleted: isDeleted,
    category: infoCompany?.category?.type
  })

  const handleDelete = async () => {
    const id = +currentCompanyId
    const input = createInput(true)
    await updateCompany({
      variables: {id, input},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        handleModalDeleteClose()
        setShowRecoveryModal(true)
        window.location.href = `${basePath}/branch/company/${currentCompanyId}/${currentBranchId}`
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      }
    });
  }

  const handleRecoveryCompany = async () => {
    const id = +currentCompanyId
    const input = createInput(false)
    await updateCompany({
      variables: {id, input},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        data && refetchInfoCompany()
        data && setShowRecoveryModal(false)
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      }
    });

  }
  useEffect(() => {
    if (infoCompany) {
      infoCompany.country.id && setSelectedCountry({
        value: infoCompany?.country?.id, label: infoCompany?.country?.name
      });
      infoCompany?.city?.id && setSelectedCity({
        value: infoCompany?.city?.id, label: infoCompany?.city?.name
      });
      infoCompany?.country?.currencies[0]?.id && setSelectedCurrency({
        value: infoCompany?.country?.currencies[0]?.id,
        label: `${infoCompany?.country?.currencies[0]?.code} - ${infoCompany?.country?.currencies[0]?.currency}`
      });
      infoCompany?.country?.timezone[0]?.id && setSelectedTimezone({
        value: infoCompany.country.timezone[0]?.id,
        label: formatTimeZone(infoCompany?.country?.timezone[0]?.name)
      });
      infoCompany?.messageLanguage?.id && setSelectedMessageLanguage({
        value: infoCompany?.messageLanguage?.id, label: infoCompany?.messageLanguage?.name
      });
    }
  }, [infoCompany]);

  useEffect(() => {
    if (selectedCountry && relationsData) {
      const country = relationsData?.relations?.countries?.find(
        country => country?.id === selectedCountry?.value
      );
      if (country?.timezone) {
        setSelectedTimezone({
          value: country?.timezone[0]?.name,
          label: formatTimeZone(country?.timezone[0]?.name),
        });
      }
    }
  }, [selectedCountry]);

  const countryOptions = countryOptionsList(relationsData);
  const cityOptions = cityOptionsList(cityData, t);
  const currencyOptions = currencyOptionsList(relationsData);
  const timezoneOptions = timezoneOptionsList(cityData?.relations?.timezones);
  const LanguagesMenuOptions = languagesMenuOptionsList(infoCompany);

  const loadCityOptions = (inputValue: string, callback: (options: any[]) => void) => {
    if (!inputValue) {
      callback(cityOptions);
      return;
    }

    const filteredOptions = cityOptions.filter((city: any) =>
        city.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(filteredOptions);
  };

  const handleCountryChange = (selectedOption: Option, setFieldValue: any) => {
    setSelectedCountry(selectedOption);
    setFieldValue('country', selectedOption?.value);
    setSelectedCity(null);
    setFieldValue('city', null)

    const currency = relationsData?.relations?.currencies?.find(currency =>
      currency?.countries?.some(c => c.id === selectedOption?.value)
    );

    if (currency) {
      const newCurrency = {
        value: currency.id,
        label: `${currency.code} - ${currency.currency}`,
      };

      setFieldValue('currency', newCurrency.value);
    }
    const country = relationsData?.relations?.countries?.find(
      ({ id }) => id === selectedOption?.value
    );
    
    if (country?.timezone?.length) {
      setFieldValue('timezone', country.timezone[0].id);
    }
  };

  const handleCityChange = (selectedOption: Option, setFieldValue: any) => {

    setSelectedCity(selectedOption);

    if (selectedOption?.value !== -1) {
      setFieldValue('otherCity', null);
    } else {
      setFieldValue('city', null)
    }
    setFieldValue('city', selectedOption?.value);
  }

  const handleCurrencyChange = (selectedOption: Option, setFieldValue: any) => {
    setSelectedCurrency(selectedOption);
    setFieldValue('currency', selectedOption ? selectedOption.value : '');
  };
  const handleMessageLanguageChange = (selectedOption: Option) => {
    setSelectedMessageLanguage(selectedOption);
  };
  const handleLanguagesMenuChange = (selectedOption: Option) => {
    setSelectedLanguagesMenu(selectedOption);
  };
  const handleMainLanguageChange = (selectedOption: Option, setFieldValue: any) => {
    setSelectedMainLanguage(selectedOption);
    setFieldValue('primaryLanguage', selectedOption.value);
  };

  const initialValues: CompanyInput = {
    name: infoCompany?.name && infoCompany?.name,
    address: infoCompany?.address && infoCompany?.address,
    phone: infoCompany?.phone && infoCompany?.phone,
    website: infoCompany?.website ? infoCompany?.website : null,
    country: infoCompany?.country?.id && infoCompany?.country?.id,
    city: infoCompany?.city?.id && infoCompany?.city?.id,
    otherCity: null,
    currency: infoCompany?.currency?.id && infoCompany?.currency?.id,
    timezone: infoCompany?.timeZone?.id && infoCompany?.timeZone?.id,
    messageLanguage: infoCompany?.messageLanguage?.id && infoCompany?.messageLanguage?.id,
    logo: infoCompany?.image ? infoCompany?.image : null,
    deleted: infoCompany?.deleted,
    category: infoCompany?.category?.type,
  };
  const handleUpdateSubmit = async (
    values: CompanyInput,
    {setSubmitting}: { setSubmitting: (isSubmitting: boolean) => void },
    initialValues: CompanyInput
  ) => {
    const id = +currentCompanyId
    const input = {
      name: values?.name,
      country: values?.country,
      city: isOtherCity ? null : values?.city,
      otherCity: values?.otherCity ? values?.otherCity : null,
      address: values?.address,
      phone: values?.phone,
      website: values?.website,
      timezone: values?.timezone,
      messageLanguage: values?.messageLanguage,
      currency: values?.currency,
      isUpdateLogo: values?.isUpdateLogo || false,
      deleted: false,
      category: infoCompany?.category?.type,
    }
    if (values?.isUpdateLogo === true) input.logo = values.logo
    await updateCompany({
      variables: {id, input},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        data && refetchInfoCompany()
        navigate(`/branches/main/branch/${currentBranchId}`, { state:  {  text: t('Saved'), toast: true } })
        setSubmitting(false);
      },
      onError: (error: ApolloError) => {
        console.error('Error creating service:', error?.graphQLErrors[0].message);
        setIsErrorModalOpen(ErrorService.errorHandling(error))
        setSubmitting(false);
      },
    });
  }
  const handlePhoto = (setFieldValue: SetFieldValueType) => {
    setFieldValue('isUpdateLogo', true);
  }
  if (loadingCompany || loadingRelations || !currentCompanyId) return <Spinner/>;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={updateCompanyFormValidationSchema(t)}
        onSubmit={(values, actions) => handleUpdateSubmit(values, actions, initialValues)}
      >
        {({
            setFieldValue,
            values,
            initialValues
          }) => {
          return (
            <Form>
              <Row className='container-max-widths-xxl flex-column flex-lg-row mt-3'>
                <Col
                  lg={8} xxl={9}
                  className='pe-xl-5'>
                  <h4 className='mb-4'>{t('basicInfo')}</h4>
                  <Row className='mb-4'>
                    <TextInput
                      id='editCompany-name-field'
                      name='name'
                      label={<span>{t('Company name')}&nbsp;<span
                        className='text-danger'>*</span></span>}
                      placeholder={t('Enter your company name')}
                      textTooltip={t('These data are displayed in the Online Registration form')}
                      maxLength={50}
                    />
                  </Row>
                  <Row className='mb-4'>
                    <Col md={2} className='text-start mb-2 mb-md-0 d-flex align-items-center'>
                      <label
                        htmlFor="country"
                      >
                        <span className="fs-7 fw-bold text-start">{t("country")}</span>
                        <span className="text-danger ms-1">*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <ReactSelect
                        id='editCompany-country-field'
                        name='country'
                        value={selectedCountry}
                        options={countryOptions}
                        placeholder={t('Choose a country')}
                        onChange={(option) => handleCountryChange(option, setFieldValue)}
                        isSearchable={true}
                      />
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col md={2} className='text-start mb-2 mb-md-0 d-flex align-items-center'>
                      <label htmlFor="city">
                        <span className="fs-7 fw-bold text-start">{t("city")}</span>
                        <span className="text-danger ms-1">*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <ReactAsyncSelect
                        id='editCompany-city-field'
                        name='city'
                        value={selectedCity}
                        options={cityOptions}
                        placeholder={t('Choose a city')}
                        onChange={(option) => handleCityChange(option, setFieldValue)}
                        isSearchable
                        isLoading={loadingCities}
                        loadOptions={loadCityOptions}
                      />
                    </Col>
                  </Row>
                  {isOtherCity && (
                    <Row className='mb-4'>
                      <TextInput
                        id='editCompany-otherCity-field'
                        name='otherCity'
                        label={<span>{t('Add city name')}&nbsp;<span
                          className='text-danger'>*</span></span>}
                        placeholder={t('')}
                      />
                    </Row>
                  )}
                  <Row className='mb-4'>
                    <TextInput
                      id='editCompany-address-field'
                      name='address'
                      label={<span>{t('address')}&nbsp;<span
                        className='text-danger'>*</span></span>}
                      placeholder={t('Enter your location')}
                      maxLength={50}
                    />
                  </Row>
                  <Row className='mb-4'>
                    <Col md={2} className='mb-2 mb-md-0 d-flex align-items-center'>
                      <label
                        htmlFor='editCompany-phone-field'
                        className='text-dark fs-7 fw-bold'
                        onClick={() => phonePickerRef.current?.focus()}
                      >
                        {t('phone')}&nbsp;<span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <PhonePicker
                        id='editCompany-phone-field'
                        phoneRef={phonePickerRef}
                        name='phone'
                        value={infoCompany?.phone ? infoCompany?.phone : ''}
                      />
                      <ErrorMessage name='phone'>
                        {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <TextInput
                      id='editCompany-website-field'
                      name='website'
                      label={t('website')}
                      placeholder={t('Site address')}
                    />
                  </Row>
                  <Row className='mb-4'>
                    <Col md={2} className='text-start mb-2 mb-md-0 d-flex align-items-center'>
                      <label htmlFor="timezone">
                        <span className="fs-7 fw-bold text-start">{t("Time zone")}</span>
                        <span className="text-danger ms-1">*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <ReactSelect
                        id='editCompany-timezone-field'
                        name='timezone'
                        options={timezoneOptions}
                        value={timezoneOptions?.find((option: any) => option.value === values.timezone)}
                        onChange={(selectedOption: any) => {
                          setFieldValue('timezone', selectedOption ? selectedOption.value : '');
                        }}
                        placeholder={t('Choose a time zone')}
                        isSearchable={false}
                        disabled={!timezoneOptions?.length}
                      />
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col md={2} className='text-start mb-2 mb-md-0 d-flex align-items-center'>
                      <label htmlFor="messageLanguage">
                            <span
                              className="fs-7 fw-bold text-start">{t("Language Messages")}</span>
                        <span className="text-danger ms-1">*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <ReactSelect
                        id='editCompany-messageLanguage-field'
                        name='messageLanguage'
                        value={LanguagesService.languageFilter(relationsData?.relations?.languages).find((option: any) => option.value === values.messageLanguage)}
                        options={LanguagesService.languageFilter(relationsData?.relations?.languages)}
                        placeholder={t('Choose a language')}
                        onChange={(selectedOption: any) => {
                          setFieldValue('messageLanguage', selectedOption ? selectedOption.value : initialValues.messageLanguage);
                        }}
                        isSearchable={true}
                      />
                    </Col>
                  </Row>
                  {
                    isMenuModule && (
                      <>
                        <Row className='mb-4'>
                          <Col md={2} className='text-start mb-2 mb-md-0'>
                            <label htmlFor="editCompany-languages-field">
                      <span
                        className="fs-7 fw-bold text-start">{t("Language versions of the menu")}</span>
                              <span className="text-danger ms-1">*</span>
                            </label>
                          </Col>
                          <Col md={10}>
                            <CheckboxSelect
                              inputId='editCompany-languages-field'
                              name='languages'
                              options={LanguagesMenuOptions}
                              onChange={handleLanguagesMenuChange}
                            />
                          </Col>
                        </Row>
                        <Row className='mb-4'>
                          <Col md={2} className='text-start mb-2 mb-md-0'>
                            <label htmlFor="primaryLanguage">
                              <span
                                className="fs-7 fw-bold text-start">{t("Main language menu")}</span>
                              <span className="text-danger ms-1">*</span>
                            </label>
                          </Col>
                          <Col md={10}>
                            <ReactSelect
                              id='editCompany-primaryLanguage-field'
                              name='primaryLanguage'
                              value={selectedMainLanguage}
                              options={selectedLanguagesMenu}
                              placeholder={t('Choose a language')}
                              onChange={(option) => handleMainLanguageChange(option, setFieldValue)}
                              isSearchable={true}
                              disabled={!selectedLanguagesMenu?.length}
                            />
                          </Col>
                        </Row>

                      </>
                    )
                  }
                  <Row className='mb-4'>
                    <Col md={2} className='text-start mb-2 mb-md-0 d-flex align-items-center'>
                      <label htmlFor="currency">
                        <span className="fs-7 fw-bold text-start">{t("Currency")}</span>
                        <span className="text-danger ms-1">*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <ReactSelect
                        id='editCompany-currency-field'
                        name='currency'
                        value={currencyOptions.find((option: any) => option.value === values.currency)}
                        options={currencyOptions}
                        placeholder={t('Choose a currency')}
                        onChange={(selectedOption) => handleCurrencyChange(selectedOption, setFieldValue )}
                        isSearchable={true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} xxl={3} className='ps-lg-0'>
                  <div
                    className='d-flex mb-3 w-100 flex-column  align-items-lg-end justify-content-lg-end'>
                    <h4
                      className='mb-4 w-100'
                      style={{maxWidth: '325px'}}>
                      {t('Logo')}
                    </h4>
                    <ImageCrop
                      previewPhoto={infoCompany?.image}
                      setFieldValue={setFieldValue}
                      fieldSetter={() => handlePhoto(setFieldValue)}
                      fieldName="logo"
                      dismissImage={() => {
                        setFieldValue('isUpdateLogo', true);
                        setFieldValue('logo', null);
                      }}

                      modalTitle={t('Logo')}
                    />
                    <ErrorMessage name="logo">{msg => <div>{msg}</div>}</ErrorMessage>
                  </div>
                </Col>
              </Row>
              <Row className='mt-2 mt-lg-5'>
                <Col lg={4} className='d-sm-flex gap-4'>
                  <Button
                    variant='outline-primary'
                    type='button'
                    className='custom-btn mb-3 mb-sm-0'
                    onClick={() => navigate(`/branches/main/branch/${currentBranchId}`)}
                  >
                    {t('cancel')}
                  </Button>
                  {role !== UserRole.EMPLOYEE
                    ?
                      <Button
                        variant='primary'
                        type='submit'
                        className='custom-btn'
                      >
                        {t('Save')}
                      </Button>
                    : null
                  }
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ModalDelete
        show={showModalDelete}
        handleClose={handleModalDeleteClose}
        title={t('Company Deletion')}
        body={`${t('Are you sure you want to delete the company and all information about it')}`}
        buttons={[
          {
            text: t('delete'),
            onClick: handleDelete,
            variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
            className: 'd-flex justify-content-center align-items-center w-100',
          },
          {
            text: t('cancel'),
            onClick: handleModalDeleteClose,
            variant: 'primary',
            className: 'w-100',
          },
        ]}
      />
      <ModalDelete
        show={showRecoveryModal}
        handleClose={handleRecoveryModalClose}
        title={t('Your Company Deleted')}
        body={`${t('Your company has been removed.\nRestore data?')}`}
        buttons={[
          {
            text: t('cancel'),
            onClick: handleRecoveryCansel,
            variant: 'primary',
            className: 'w-100',
          },
          {
            text: t('Restore'),
            onClick: handleRecoveryCompany,
            variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
            className: 'd-flex justify-content-center align-items-center w-100',
          }
        ]}
      />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  )
}


export default UpdateCompanyForm;
