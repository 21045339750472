import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TableHeader, Pagination} from '@services/ui-components';
import {Row, Col} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import ProgressBar from '@services/ui-components/src/ProgressBar/ProgressBar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useGetMenuItemsQuery } from '@me-team/host/main/menu/graphql/menu.hooks';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';

const MenuItems: React.FC = () => {
    const {t} = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3);
    const [currentFilter, setCurrentFilter] = useState({name: '', branchTypeId: ''});
    const [filter, setFilter] = useState({name: '', branchTypeId: ''});
    const [showFilters, setShowFilters] = useState(false);
    const toggleFilters = () => setShowFilters(!showFilters);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<string | null>(null);

    const handleDeleteClick = (id: string) => {
        setShowDeleteConfirm(true);
        setItemToDelete(id);
    };

    const handleClose = () => {
        setShowDeleteConfirm(false);
        setItemToDelete(null);
    };

    const handleConfirmDelete = () => {
        if (itemToDelete) {
            handleClose();
        }
    };

    const {data: menuItemsData, loading, error} = useGetMenuItemsQuery({
        variables: {
            itemsPerPage,
            page: currentPage,
            companyId: +currentCompanyId
        },
        skip: !currentCompanyId,
    })

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentFilter({...currentFilter, name: event.target.value});
    };

    const handleBranchTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrentFilter({...currentFilter, branchTypeId: event.target.value});
    };

    const handleApplyFilter = () => {
        setFilter(currentFilter);
        setCurrentPage(1);
    };

    const handleClearFilter = () => {
        setFilter({name: '', branchTypeId: ''});
        setCurrentFilter({name: '', branchTypeId: ''});
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];

    const handleChange = (selectedOption: string) => {
        setSelectedLanguage(selectedOption);
        console.log(`Option selected:`, selectedOption);
    };

    const handleDelete = (id: string) => {
        console.log("Deleting item with id:", id);
    };

    const steps = [
        { key: 'menu', label: 'МЕНЮ' },
        { key: 'categories', label: 'КАТЕГОРІЇ' },
        { key: 'dishes', label: 'СТРАВИ' },
        { key: 'view', label: 'ПЕРЕГЛЯД' },
        { key: 'codegen', label: 'ГЕНЕРАЦІЯ КОДА' },
    ];

    const menuItems = menuItemsData?.user.company.menuItems.items.map((item: any) => ({
        id: item.id,
        name: item.name,
        categories: item.categories.name,
        price: item.portions.price,
        volume: item.portions.volume
    })) || [];

    const pageCount = parseInt(menuItemsData?.user.company.menuItems.pageCount || '0');
    const totalItems = menuItemsData?.user.company.menuItems.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <TableHeader
                title="Список страв"
                paths={[{ path: "/branches", label: "Branches" }, { path: "/branch/menu/items", label: "List" }]}
                buttons={
                    <Row className='gx-1'>
                        <Col lg={3}>
                            <Select
                                value={selectedLanguage}
                                onChange={handleChange}
                                options={options}
                            />
                        </Col>
                        <Col lg={3}>
                            <button className="btn btn-primary w-100"><i className='bi bi-plus-lg me-2'></i>{t('Create menu')}</button>
                        </Col>
                        <Col lg={3}>
                            <button onClick={() => navigate('create-item')} className="btn btn-primary w-100"><i className='bi bi-plus-lg me-2'></i> {t('Add dish')}</button>
                        </Col>
                        <Col lg={3}>
                            <button className="btn btn-additional-violet fw-normal border-primary text-primary w-100" onClick={toggleFilters}><i className="bi bi-funnel me-2"></i>{t("filter")}</button>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
            >
                <Row className='gx-3'>
                    <Col lg={9}>
                        <Row className='gx-3'>
                            <Col lg={4}>
                                <input type="text" className="form-control" placeholder={t("search")}
                                       value={currentFilter.name}
                                       onChange={handleNameChange}/>
                            </Col>
                            <Col lg={4}>
                                <Select
                                    value={selectedLanguage}
                                    onChange={handleChange}
                                    options={options}
                                />
                            </Col>
                            <Col lg={4}>
                                <Select
                                    value={selectedLanguage}
                                    onChange={handleChange}
                                    options={options}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3}>
                        <Row className='gx-3 h-100'>
                            <Col lg={6}>
                                <button className="btn btn-outline-primary w-100 h-100"
                                        onClick={handleApplyFilter}>{t("apply")}</button>
                            </Col>
                            <Col lg={6}>
                                <button className="btn btn-outline-dark border-grey w-100 h-100 fw-normal"
                                        onClick={handleClearFilter}>{t("clear")}</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableHeader>
            <ProgressBar currentStep={1} steps={steps}/>
            <table className="table">
                <thead>
                <tr className='fs-7'>
                    <th>{t("photo")}</th>
                    <th>{t("name")}</th>
                    <th>{t("categoriesSubcategories")}</th>
                    <th>{t("pricePrices")}</th>
                    <th>{t("volume")}</th>
                    <th>{t("menu")}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {menuItems.length > 0 ? (
                    menuItems.map((menuItem: any) => (
                        <tr key={menuItem.id}>
                            <td><img src={menuItem.image} alt={menuItem.name} style={{width: '50px'}}/></td>
                            <td>{menuItem.name}</td>
                            <td>{menuItem.categories}</td>
                            <td>{menuItem.price}</td>
                            <td>{menuItem.volume}</td>
                            <td>
                                <div className='flex-column d-flex gap-2'>
                                     <button className="btn btn-light btn-sm">{t("Клонувати страву")}</button>
                                    <button className="btn btn-light btn-sm" onClick={() => handleDelete(menuItem.id)}>{t("Редагувати страву")}</button>
                                    <button className="btn btn-light btn-sm" onClick={() => handleDeleteClick(menuItem.id)}>{t("Видалення страви")}</button>
                                </div>


                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={7} style={{ textAlign: 'center' }}>
                            <div>
                                <p>{t("noDishes")}</p>
                                <button className="btn btn-primary" onClick={() => {}}>
                                    {t("Create dish")}
                                </button>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-center">
                <Pagination
                    t={t}
                    pages={Number(pageCount)}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    firstItemIndex={firstItemIndex}
                    lastItemIndex={lastItemIndex}
                    totalItems={totalItems}
                />
            </div>
            <Modal show={showDeleteConfirm} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Delete dish")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("deleteDishConfirmation")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        {t("Delete dish")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MenuItems;
