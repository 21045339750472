export enum Status {
    CREATED = -2,
    FAIL = -1,
    IN_PROGRESS = 0,
    SUCCESS = 1,
    VALIDATION_FAILED = 2,
}

export enum JobTypeCase {
    IMPORT = 'IMPORT',
    EXPORT = 'EXPORT',
}

export enum EntityType {
    CLIENTS = 'clients',
    EMPLOYEES = 'employees',
    SERVICES = 'services',
    SERVICE_CATEGORIES = 'service-categories-new',
    IMPORT = 'csvImportJob',
    EXPORT = 'exportJob'
}

export enum ImportEntityType {
    EMPLOYEE_TYPE = 'employee',
    SERVICE_TYPE = 'service',
    SERVICE_CATEGORY_TYPE = "serviceCategoryNew"
}

export enum UserRole {
    OWNER = 1,
    ADMINISTRATOR = 3,
    EMPLOYEE = 5,
}

export enum bookingStatusIds {
    APPROVE_CLIENT = 1,
    WAITING_FOR_CLIENT = 2,
    CLIENT_ARRIVED = 3,
    CLIENT_HAS_NOT_ARRIVED = 4,
    BOOKING_WAS_CANCELED = 5,
    COMPLETED = 6,
    COMPLETED_WITH_WAREHOUSE = 7
}

export enum notificationRecordOption {
    DO_NOT_NOTIFY = 0,
    IN_30_MIN = 1,
    IN_1_HOUR = 2,
    IN_2_HOUR = 3,
    IN_3_HOUR = 4,
    IN_ONE_DAY = 5,
    IN_TWO_DAYS = 6,
}

export enum employeeWorkStatus {
    FIRED = 4
}

export enum GoogleTags {
    SCRIPT_ID = 'google-tag-manager-script',
    ADSENSE_SCRIPT_ID = 'google-adsense-script',
    GTM_ID = 'GTM-W5LK2MNQ',
    ADSENSE_CLIENT_ID = 'ca-pub-7056109210876208'
}



