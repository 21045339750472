import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Col, Container } from "react-bootstrap";

const Layout: React.FC = () => {
  const { pathname } = useLocation();

  if (pathname.includes("/logbook/statistic")) {
    return (
      <Col xs={12} md={8} lg={9} xl={10} className="pt-5 pt-md-0 mx-auto">
        <Outlet />
      </Col>
    );
  }

  return (
    <Col xs={12} md={8} lg={9} xl={10} className="pt-5 pt-md-0 mx-auto">
      <Container fluid className="px-3 px-xxl-1">
        <Col xs={12} xl={10} className="offset-xl-1">
          <Outlet />
        </Col>
      </Container>
    </Col>
  );
};

export default Layout;
