import React from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import { Accordion } from "react-bootstrap";

type AccordionItem = {
    label: string;
    to: string;
    href?: string;
};

type AccordionNavProps = {
    title: string;
    items: AccordionItem[];
    icon: string;
    eventKey: string;
    handleCloseNavbar?: () => void;
    firstItemLink: string;
};
const AccordionNav: React.FC<AccordionNavProps> = ({title, items, icon, eventKey, handleCloseNavbar, firstItemLink}) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Accordion.Item eventKey={eventKey} className="mb-1">
            <Accordion.Header>
                <div className="btn btn-accordion-icon d-flex justify-center align-items-center p-1 me-2 z-5">
                    <i className="bi bi-caret-right-fill fs-10 w-100"></i>
                </div>
                <span className="d-flex align-items-center text-dark w-100" role="link" onClick={() => navigate(`/${firstItemLink}`)}>
                    <i className={`bi ${icon} me-2`}></i>
                    <span className="w-100">{title}</span>
                </span>
            </Accordion.Header>
            <Accordion.Body className="ms-4 px-2 py-0 mt-1">
                {items.map((item, index) => {
                    const isEmployeeEditSelected = `/${item.to}` === '/employees-list' && location.pathname.includes('edit');
                    const isAccordionActive = `/${item.to}` === location.pathname || (location.pathname.startsWith(`/${item.to}`) && !location.pathname.includes('add-employee'));
                    const isItemHighlighted = isAccordionActive || isEmployeeEditSelected;

                    return (
                        <div key={index} className="accordion-body-item mb-1 rounded">
                            {item.to ? (
                                <NavLink to={item.to}
                                         end
                                         className={({isActive}) => `accordion-body-item-link text-decoration-none text-dark d-block py-2 px-4 h-100 w-100 rounded-1 ${isActive || isItemHighlighted ? "bg-additional-violet" : ""}`}
                                         onClick={handleCloseNavbar}>
                                    {item.label}
                                </NavLink>
                            ) : (
                                <a href={item.href} className="accordion-body-item-link text-decoration-none text-dark d-block py-2 ps-4 h-100 w-100">
                                    {item.label}
                                </a>
                            )}
                        </div>
                    )
                })}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default AccordionNav;