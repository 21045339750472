import { CallBackProps, EVENTS } from 'react-joyride';

export const handleJoyrideCallback = (
    data: CallBackProps,
    setStepIndex: (index: number) => void,
    mutationForOnlineRecord: () => void,
) => {
    const { action, index, type } = data;
    if (type === EVENTS.STEP_AFTER && action === 'next' && index !== 8) {
        const nextStepIndex = index + 1;
        localStorage.setItem('step', nextStepIndex.toString());
        setStepIndex(nextStepIndex);

        if (index === 17) {
            mutationForOnlineRecord();
        }
    }
};


export const changeStep = (
    stepIndex: number,
    setStepIndex: (index: number) => void,
    direction: string
): void => {
    let newStepIndex = stepIndex;
    if (direction === 'next') {
        newStepIndex = stepIndex + 1;
    } else if (direction === 'back' && stepIndex > 0) {
        newStepIndex = stepIndex - 1;
    }
    setStepIndex(newStepIndex);
    localStorage.setItem('step', newStepIndex.toString());
};

export const changeStepStorage = (direction: string): void => {
    const currentStep = localStorage.getItem('step');
    let stepIndex = parseInt(currentStep)
    let newStepIndex = stepIndex;
    if (direction === 'next') {
        newStepIndex = stepIndex + 1;
    } else if (direction === 'back' && stepIndex > 0) {
        newStepIndex = stepIndex - 1;
    }
    localStorage.setItem('step', newStepIndex.toString());
};
