import React from 'react';
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { uk, vi, enUS, ar, hi, id, th } from "date-fns/locale";
import {useTranslation} from 'react-i18next';

const locales: Record<string, Locale> = {
    uk: uk,
    vi: vi,
    ar: ar,
    hi: hi,
    id: id,
    th: th,
    en: enUS,
};

const CustomDatePicker: React.FC<ReactDatePickerProps> = ({
                                                              selected,
                                                              id,
                                                              className,
                                                              placeholderText,
                                                              onChange,
                                                              wrapperClassName,
                                                              dateFormat,
                                                              filterDate,
                                                              maxDate,
                                                              showYearDropdown,
                                                              yearDropdownItemNumber,
                                                              scrollableYearDropdown,
                                                              calendarStartDay
                                                          }) => {
    const { i18n } = useTranslation();

    return (
        <DatePicker
            selected={selected}
            id={id}
            className={className}
            placeholderText={placeholderText}
            onChange={onChange}
            wrapperClassName={wrapperClassName}
            dateFormat={dateFormat}
            filterDate={filterDate}
            locale={locales[i18n.language] || enUS}
            maxDate={maxDate}
            showYearDropdown={showYearDropdown}
            yearDropdownItemNumber={yearDropdownItemNumber}
            scrollableYearDropdown={scrollableYearDropdown}
            calendarStartDay={calendarStartDay}
        />
    );
};

export default CustomDatePicker;