import React, {useEffect, useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {Col, Row, Modal, Button, Container} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from "@services/ui-components/src/DatePicker/DatePicker";
import CustomReactSelect from '@services/ui-components/src/select/CustomReactSelect';
import ToastComponent from "../../../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../../../hooks/useToast";
import {
    useDeleteDismissEmployeeMutation,
    useDismissEmployeeMutation,
    useGetBookingsForEmployeeLazyQuery,
    useGetDismissalReasonQuery,
    useUpdateDismissEmployeeMutation
} from '@me-team/host/main/employeeList/graphql/employeeList.hooks';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import ErrorService from '../../../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {getNextDay} from '../../utils/dateUtils';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {CustomModal} from '@services/ui-components';
import {useNavigate} from 'react-router-dom';

interface Employee {
    id: number;
    name: string;
    surname: string;
    position: {
        id: number;
        name: string;
    };
    status?: {
        id: number;
        name: string;
    };
    dismissalEmployee: {
        id: number;
        endDateCooperation?: string
        comment?: string
        employeeDismissalReason?: {
            id?: number
        }
    }
}
interface FormData {
    employeeId: number;
    endOfCooperation: string;
    reasonId: number;
    comment: string;
}

const DeleteEmployeeModal: React.FC<{ show: boolean, onHide: () => void, selectedEmployee: Employee, refetch: () => void;  }> = ({ show, onHide, selectedEmployee, refetch, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showBookingConflictModal, setShowBookingConflictModal] = useState(false)
    const [formData, setFormData] = useState<FormData>({
        employeeId: +"",
        endOfCooperation:  selectedEmployee?.dismissalEmployee?.endDateCooperation ||  "",
        reasonId: +"",
        comment: ""
    });
    const employeeDismissalReasonId =  selectedEmployee?.dismissalEmployee?.employeeDismissalReason?.id ? selectedEmployee?.dismissalEmployee?.employeeDismissalReason?.id :  ''
    const [reasonId, setReasonId] = useState({ reasonId: +'' })
    const [errorComment, setErrorComment] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [date] = useState();
    useEffect(() => {setReasonId({reasonId: selectedEmployee?.dismissalEmployee?.employeeDismissalReason?.id})}, [selectedEmployee])
    const { showToast, toastText, toggleShowToast, setShowToast } = useToast();
    const handleCloseBookingConflictModal = () => setShowBookingConflictModal(false)

    const [deleteEmployee] = useDismissEmployeeMutation()
    const [updateDeleteEmployee] = useUpdateDismissEmployeeMutation()
    const [dismissDeleteEmployee] = useDeleteDismissEmployeeMutation({
        context: {
            errorType: 'local',
        },
    })
    const { data } = useGetDismissalReasonQuery()

    const [getBookingsForEmployee,
        {
            data: bookingsForEmployeeData,
            loading: bookingsForEmployeeLoading
        }] = useGetBookingsForEmployeeLazyQuery({
            context: { errorType: 'local' },
            onError: (error: ApolloError) => {
                setIsErrorModalOpen(ErrorService.errorHandling(error));
            },
        }
    );

    const toggleConfirmDeleting = async () => {

        setShowConfirmationModal(!showConfirmationModal)
    }

    const dismissDeleting = async (event: React.MouseEvent) => {
        event.preventDefault();

        try {
            const response = await dismissDeleteEmployee({
                variables: {
                    id: selectedEmployee?.dismissalEmployee?.id,
                    companyId: +currentCompanyId,
                }
            })
            refetch()
            onHide()
            toggleShowToast(t('Restored'))
        } catch (error) {
            console.error('Error dismissDeleting employee:', error);
        }
    }
    const confirmDeleting = async () => {
        if (!formData) {
            console.error('Form data is not defined');
            return;
        }
        try {
            if (selectedEmployee?.dismissalEmployee?.id) {
                const response = await updateDeleteEmployee({
                    variables: {
                        dismissalId: selectedEmployee?.dismissalEmployee?.id,
                        endOfCooperation: formData.endOfCooperation,
                        reason: formData.reasonId,
                        comment: formData.reasonId === 2 ? '' : formData.comment,
                        companyId: +currentCompanyId
                    },
                });
            } else {
                const response = await deleteEmployee({
                    variables: {
                        employeeId: formData.employeeId,
                        endOfCooperation: formData.endOfCooperation,
                        reason: formData.reasonId,
                        comment: formData.reasonId === 2 ? '' : formData.comment,
                        companyId: +currentCompanyId
                    },
                });
            }
            refetch();
            toggleConfirmDeleting();
            toggleShowToast(t('Employee fired'));
            refetch()
            toggleConfirmDeleting()
            toggleShowToast(t('Employee fired'))
        } catch (error) {
            console.error('Error dismissing employee:', error);
        }
    };


    const handleChange = (date: Date, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        if (date) {
            setStartDate(date);
            const formattedDate = date.toISOString().slice(0, 10);
            setFieldValue('endOfCooperation', formattedDate);
        }
    };


    const handleReasonId = (name: string, id: string) => {
        setReasonId(prevFilter => ({
            ...prevFilter,
            reasonId: +id
        }));
    };
    const validationSchema = Yup.object().shape({
        endOfCooperation: Yup.string()
            .required(t('End date of cooperation is mandatory')),
        reasonId: Yup.string()
            .required(t('Please select a reason')),
    })

    if (bookingsForEmployeeLoading || !currentBranchId) return <Spinner />

    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => {
                    setStartDate(null);
                    setErrorComment(false)
                    onHide();
                }}
                centered
            >
                <Modal.Header className='border-0' closeButton>
                    <h5 className="m-0">{t('Dismissal of Employee')}</h5>
                </Modal.Header>
                <Formik
                    initialValues={{
                        employeeId: +selectedEmployee?.id,
                        endOfCooperation: "",
                        reasonId: null,
                        comment: selectedEmployee?.dismissalEmployee?.comment || ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        if (+values.reasonId === 4 && values.comment.length < 1) {
                            setErrorComment(true)
                            return
                        }
                        try {
                            const { data } = currentCompanyId && values.employeeId &&
                            await getBookingsForEmployee({
                                variables: {
                                    companyId: +currentCompanyId,
                                    employeeId: values.employeeId,
                                    removeCanceledBookings: false,
                                    filterByCompany: true,
                                    startDate: getNextDay(values.endOfCooperation),
                                },
                            });
                            const isFutureBookingsEmployee = data?.user?.company?.employees?.employees?.[0]?.bookings?.bookings?.length > 0

                            if (isFutureBookingsEmployee) {
                                setShowBookingConflictModal(true);
                                setStartDate(null);
                                onHide();
                            } else {
                                setFormData(values);
                                toggleConfirmDeleting();
                                setStartDate(null);
                                onHide();
                            }
                        } catch (error) {
                            console.error('Error dismissing employee:', error);
                        } finally {
                            actions.setSubmitting(false);
                        }
                    }}
                >
                    {({ isSubmitting, setFieldValue, values, errors }) => (
                        <Form>
                            <Modal.Body style={{ padding: '16px 28px 16px 28px' }}>


                                <Row className="py-3">
                                    <Col xs={3} className="my-auto">
                                        <strong>
                                            <span className="fs-7">
                                                {t('Name')}
                                            </span>
                                        </strong>
                                    </Col>
                                    <Col xs={9}>
                                        <span className="fs-7">{selectedEmployee?.name}</span>
                                    </Col>
                                </Row>

                                <Row className="py-3">
                                    <Col xs={3} className="my-auto">
                                        <strong>
                                            <span className="fs-7">
                                                {t('Last name')}
                                            </span>
                                        </strong>
                                    </Col>
                                    <Col xs={9}>
                                        <span className="fs-7">{selectedEmployee?.surname}</span>
                                    </Col>
                                </Row>

                                <Row className="py-3">
                                    <Col xs={3} className="my-auto">
                                        <strong>
                                            <span className="fs-7">
                                                {t('Position')}
                                            </span>
                                        </strong>
                                    </Col>
                                    <Col xs={9}>
                                        <span className="fs-7">{selectedEmployee?.position.name}</span>
                                    </Col>
                                </Row>

                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 pt-2">
                                        <label  htmlFor="End Date of">
                                            <strong className="text-dark fs-7">
                                                {t('End Date of')}
                                            </strong>
                                            <br />
                                            <strong className='fs-7'>
                                                {t('Cooperation')} <span className="text-danger">*</span>
                                            </strong>
                                        </label>

                                    </Col>
                                    <Col xs={9} className="text-dark">
                                        <DatePicker
                                            id='End Date of'
                                            name='endOfCooperation'
                                            className={`form-control fs-7 ${errors.endOfCooperation  ? "border-danger" : ""} `}
                                            wrapperClassName='w-100'
                                            selected={startDate ? startDate : selectedEmployee?.dismissalEmployee?.endDateCooperation}
                                            placeholderText={t('dd.mm.yyyy')}
                                            onChange={(date) => handleChange(date, setFieldValue)}
                                            dateFormat="dd.MM.yyyy"
                                        />
                                        <ErrorMessage name="endOfCooperation">
                                            {msg => <p className="fs-7 p-1 text-danger m-0">{msg}</p>}
                                        </ErrorMessage>
                                    </Col>
                                </Row>

                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 pt-2">
                                        <label htmlFor="Reason-for-Dismissal">
                                            <strong className="text-dark fs-7">
                                                {t('Reason for Dismissal')}
                                                <span className="text-danger"> *</span>
                                            </strong>
                                        </label>

                                    </Col>
                                    <Col xs={9} className="text-dark fs-7">
                                        <CustomReactSelect
                                            optionsArray={data?.dismissalReason}
                                            placeholder={t('Reason for Dismissal')}
                                            setField={setFieldValue}
                                            fieldName="reasonId"
                                            value="id"
                                            additionalParam={handleReasonId}
                                            name={"Reason-for-Dismissal"}
                                            initialValue={
                                            data?.dismissalReason
                                                ?.map(option => ({
                                                    label: option.name,
                                                    value: option.id.toString(),
                                                }))
                                                .find(
                                                    option =>
                                                        option.value ===
                                                        selectedEmployee?.dismissalEmployee?.employeeDismissalReason?.id.toString()
                                                )}
                                            id={"Reason for Dismissal"}
                                        />
                                        <ErrorMessage name="reasonId">
                                            {msg => <p className="fs-7 p-1 text-danger m-0">{msg}</p>}
                                        </ErrorMessage>
                                    </Col>
                                </Row>

                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 pt-2">
                                        <label htmlFor="Comment">
                                            <strong className={`${reasonId.reasonId !== 4 && "text-grey"} fs-7`}>
                                                {t('Comment')}
                                                <span className="text-danger"> *</span>
                                            </strong>
                                        </label>
                                    </Col>
                                    <Col xs={9} className="text-dark" data-controller="component--datepicker" data-current-locale="en">
                                        <Field
                                            as="textarea"
                                            name="comment"
                                            rows={3}
                                            className="form-control fs-7"
                                            placeholder={t('Explain the reason for dismissal')}
                                            disabled={reasonId.reasonId !== 4}
                                            maxLength={200}
                                            id="Comment"
                                        />
                                        <span className={`fs-7 ${values.comment.length > 200 ? "text-danger" : "text-grey"} `}>
                                            {values.comment.length}/200
                                        </span>
                                        {errorComment && reasonId.reasonId === 4 ? (
                                            <p className="fs-7 p-1 text-danger m-0">{t('Explain the reason for dismissal')}</p>
                                        ) : ''}
                                    </Col>
                                </Row>

                            </Modal.Body>
                            <div style={{ padding: ' 12px' }}>
                                <Row className="gx-3  justify-content-end">
                                    {selectedEmployee?.dismissalEmployee?.id  && (
                                        <Col>
                                            <Button variant="danger" className="text-truncate" onClick={dismissDeleting}>
                                                {t('Cancel Dismissal')}
                                            </Button>
                                        </Col>
                                    )}

                                    <Col xs={3}>
                                        <Button
                                                variant="outline-dark"
                                                className="w-100 h-100 fw-normal"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setStartDate(null);
                                                    setErrorComment(false)
                                                    onHide();
                                                }}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </Col>
                                    <Col xs={3}>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="w-100 text-truncate"

                                        >
                                            {t('Confirm')}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>

                        </Form>)}
                </Formik>
            </Modal >

            <Modal
                show={showConfirmationModal}
                onHide={toggleConfirmDeleting}
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Dismissal of Employee')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {t('Do you really want to dismiss')} {selectedEmployee?.name} {selectedEmployee?.surname} {t('from the position of')}  {selectedEmployee?.position?.name}?
                </Modal.Body>
                <Modal.Footer >

                    <Col>
                        <button className="btn btn-outline-primary border-grey w-100 h-100 fw-normal" onClick={toggleConfirmDeleting}>
                            {t('Cancel')}
                        </button>
                    </Col>
                    <Col>
                        <button className="btn btn-primary w-100 text-truncate" onClick={confirmDeleting}>
                            {t('Confirm')}
                        </button>
                    </Col>
                </Modal.Footer>
            </Modal>
            <CustomModal
                show={showBookingConflictModal}
                handleClose={handleCloseBookingConflictModal}
                title={
                    <Container>
                        <Row>
                            <h5 className='m-0 p-0 fw-normal'>{t('It is impossible to dismiss an employee.')}</h5>
                        </Row>
                    </Container>
                }
                body={
                    <Container>
                        <Row>
                            <Col>
                                <p className='mb-0'>
                                    {t('It is not possible to fire an employee because the' +
                                      ' employee has a booking for this period. Go to the Logbook' +
                                      ' to move or cancel them.')}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                }
                buttons={[{
                    text: t('Go to Logbook'),
                    onClick: () => navigate(`/logbook/journal/${currentBranchId}`),
                    variant: 'primary',
                    className: 'fw-normal',
                    positionButtons: 'text-center'
                }]}
            />

            { showToast &&  <ToastComponent show={showToast} setShow={setShowToast}  text={toastText}/>}
            {isErrorModalOpen ?
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                /> : null
            }
        </>
    );
};

export default DeleteEmployeeModal;
