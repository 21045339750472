import React, { useEffect } from "react";
import { useGetCountryGeoQuery } from "@me-team/host/main/branches/graphql/branches.hooks";
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';

const excludeHotjarCountries = ["UA", "CZ"];

const GlobalWidgets: React.FC = () => {
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);

  const { data: countryGeoData } = useGetCountryGeoQuery({
    skip: !currentCompanyId
  });

  const isoCode = countryGeoData?.countryGeo?.country?.isoCode;
  useEffect(() => {
    if (!isoCode || excludeHotjarCountries.includes(isoCode)) return;
    const hotjarScript = document.createElement("script");
    hotjarScript.innerHTML = `
      (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3911500,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.head.appendChild(hotjarScript);

    return () => {
      document.head.removeChild(hotjarScript);
    };
  }, [isoCode]);

  return null;
};

export default GlobalWidgets;
