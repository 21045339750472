import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {CalendarWrapperComponent} from './CalendarWrapperComponent';
import {CalendarFilterI, getLogbookDates} from './constants';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {
  currentBranchIdVar,
  currentCompanyIdVar,
  currentUserRoleVar
} from '@me-team/host/src/apollo/globalVar/state';
import EmptyList from '@services/ui-components/src/EmptyList/EmptyList';
import {
  useGetBookingByIdQuery,
  useGetBookingsListQuery
} from '@me-team/host/main/logbook/graphql/logbook.hooks';
import {useGetInfoCompanyQuery} from '@me-team/host/main/company/graphql/company.hooks';
import {Spinner} from '@services/ui-components';
import {UserRole} from '../../utils/enums';
import {EmployeeProxy} from '@me-team/host/main/graphql/types';

type CalendarWrapperProps = {
  filters: CalendarFilterI,
  setNotificationOfMissingData: React.Dispatch<React.SetStateAction<string>>;
  notificationOfMissingData: string;
};

const CalendarWrapper: React.FC<CalendarWrapperProps> = ({
                                                           filters,
                                                           setNotificationOfMissingData,
                                                           notificationOfMissingData
                                                         }) => {
  const location = useLocation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [showPreloader, setShowPreloader] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const getDates = currentBranchId && getLogbookDates(currentBranchId.toString())
  const newBookingId: number = location.state?.recordId
  const {data: infoCompanyData, loading} = useGetInfoCompanyQuery({
    variables: { companyId: +currentCompanyId },
    skip: !currentCompanyId,
    context: {
      errorType: "local",
    },
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })

  const {data: bookingData, loading: loadingBooking} = useGetBookingByIdQuery({
    skip:!newBookingId || !currentCompanyId || !currentBranchId,
    variables: {
      branchId: +currentBranchId,
      bookingId: newBookingId && newBookingId,
      companyId: +currentCompanyId,
    },
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })
  const newDate: string =  bookingData?.user?.company?.branches?.[0].bookings?.bookings?.[0].date
  const timeZone: string = infoCompanyData?.user?.company?.timeZone?.name

  const {data: bookingListData, loading: loadingBookingList} = useGetBookingsListQuery({
    variables: {
      branchId: +currentBranchId,
      isDeleted: false,
      startDate: getDates?.startDate && getDates?.startDate,
      endDate: getDates?.endDate && getDates?.endDate,
      statusId: filters?.statusId && filters?.statusId,
      serviceId: filters?.serviceId && filters?.serviceId,
      employeeId: filters?.employeeId && filters?.employeeId,
      positionId: filters?.positionId && filters?.positionId,
      companyId: +currentCompanyId
    },

    skip: isEmployeeRole || !filters?.statusId && !filters?.serviceId && !filters?.employeeId && !filters?.positionId ||
    !currentCompanyId || !currentBranchId,
    onCompleted: (data) => {
      const bookingsList = data?.user?.company?.branches?.[0]?.bookingsList || [];
      const employeesList = data?.user?.company?.employeesList || [];
      const employees: EmployeeProxy[] = data?.user?.company?.employees?.employees;
      const statusEmployeeActive = 2;
      const selectedEmployee = filters?.employeeId && employees ? employees?.find((employee) => employee.status.id === statusEmployeeActive) : null;
      if ((filters.statusId || filters.serviceId) && bookingsList.length === 0) {
        setNotificationOfMissingData('No records')
      } else if ((filters.positionId && employeesList.length === 0) ||
        (filters.employeeId && !selectedEmployee)) {
        setNotificationOfMissingData('No data for the request')
      } else {setNotificationOfMissingData('')}
    },
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })

  const showPreloaderHandler = () => {
    setShowPreloader(true)
  }
  const hidePreloaderHandler = () => {
    setShowPreloader(false);
  }

  const secondComponent = React.useMemo(() => <CalendarWrapperComponent
    currentCompanyId={+currentCompanyId}
    currentBranchId={+currentBranchId}
    filters={filters}
    timeZone={timeZone}
    showPreloaderHandler={showPreloaderHandler}
    hidePreloaderHandler={hidePreloaderHandler}
    newBookingId={newBookingId}
    newDate={newDate}
  />, [
    currentCompanyId,
    currentBranchId,
    JSON.stringify(filters),
    newBookingId,
    timeZone,
    newDate
  ]);

  if (
    loading ||
    loadingBookingList && (!isEmployeeRole && (filters?.statusId || filters?.serviceId || filters?.employeeId || filters?.positionId)) ||
    !currentBranchId ||
    !currentCompanyId
  ) {
    return <Spinner />;
  }

  return (
    <>
      {showPreloader && <div className='d-flex all-unset align-items-center justify-content-center position-fixed top-50 end-50 z-3'>
        <div className='preloader__spinner'></div>
      </div>}
      {
        !isEmployeeRole && (filters.serviceId || filters.statusId || filters.positionId || filters.employeeId) &&
        notificationOfMissingData
          ?
          <EmptyList title={notificationOfMissingData} customClass='mb-5' />
          : secondComponent
      }
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {setIsErrorModalOpen(null)}}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  )
}

export default CalendarWrapper;
