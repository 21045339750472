import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BookingStatistic: React.FC = () => {
  const { branchId } = useParams();
  const { i18n } = useTranslation();

  useLayoutEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const languagePath =
    i18n.language === "en"
      ? ""
      : i18n.language === "uk"
      ? "/ua"
      : `/${i18n.language}`;

  return (
    <iframe
      src={`${process.env.REACT_APP_STATISTICS_URL}/${i18n.language}/mepos?url=${process.env.REACT_APP_POS_URL}${languagePath}/ajax/booking/statistic/${branchId}`}
      className="w-100 min-vh-100"
    />
  );
};

export default BookingStatistic;
