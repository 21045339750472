import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Col, Row} from 'react-bootstrap';
import {FiltersButton, InfoBanner, Spinner, TableHeader} from '@services/ui-components';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {CalendarFilterI} from './constants';
import CalendarWrapper from './CalendarWrapper';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import CalendarFilterWrapper from './CalendarFilterWrapper';
import {UserRole} from '../../utils/enums';
import ErrorService from '../../services/ErrorService';
import {
  useCheckBranchDataQuery,
  useGetCurrentEmployeeIdQuery
} from '@me-team/host/main/logbook/graphql/logbook.hooks';
import {useNavigate} from "react-router-dom";
import ToastComponent from "../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../hooks/useToast";
import {BranchProxy} from '@me-team/host/main/graphql/types';
import NotificationsModal from './NotificationsForNewCompany/NotificationsModal';

const Logbook: React.FC = () => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate()
  const userRole = useReactiveVar(currentUserRoleVar);
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [showFilters, setShowFilters] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [checkModalShow, setCheckModalShow] = useState<boolean>(false);
  const [notificationOfMissingData, setNotificationOfMissingData] = useState<string>('');

  const closeCheckModal = () => setCheckModalShow(false);

  const {data: checkBranchData, loading: loadingCheckBranchData} = useCheckBranchDataQuery({
    skip: !currentCompanyId,
    context: {
      errorType: "global",
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const isBooking = data.user.currentBranch.bookingsList.length > 0
      const isAnyActiveServiceEmployeeRelations = data.user.currentBranch.isAnyActiveServiceEmployeeRelations
      const isAnyActiveServices = data.user.currentBranch.isAnyActiveServices
      const isAnyWorkScheduleForWorkingEmployees = data.user.currentBranch.isAnyWorkScheduleForWorkingEmployees
      if ((!isAnyActiveServiceEmployeeRelations || !isAnyActiveServices || !isAnyWorkScheduleForWorkingEmployees) && !isBooking ) {
        setCheckModalShow(true);
      }
      if (isAnyActiveServiceEmployeeRelations && isAnyActiveServices && isAnyWorkScheduleForWorkingEmployees && !isBooking) {
        toggleShowToast(t('The logbook is ready to go!'));
      }
    },
    onError: (error: ApolloError) => {
      console.error(error)
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })

  const currentBranch: BranchProxy = checkBranchData?.user?.currentBranch

  const {data: currentEmployeeIdData, loading} = useGetCurrentEmployeeIdQuery({
    skip: !currentCompanyId,
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })
  const currentEmployeeId = currentEmployeeIdData?.user?.employee?.id

  const initialValueFilter: CalendarFilterI = {
    statusId: null,
    positionId: null,
    employeeId: isEmployeeRole ? currentEmployeeId : null,
    serviceId: null,
  }

  const { showToast, toastText, setShowToast, toggleShowToast  } = useToast();

  const [filters, setFilters] = useState<CalendarFilterI>(initialValueFilter);
  useEffect(() => {
    if (isEmployeeRole) {
      setFilters((prev) => ({ ...prev, employeeId: currentEmployeeId }))
    }
  }, [isEmployeeRole,currentEmployeeId]);

  const handleCreateRecord = () => {
    navigate('/logbook/add-record')
  };

  const toggleFilters = () => setShowFilters(!showFilters);

  if (loadingCheckBranchData) return <Spinner/>;

  return (
    <>
      <Row className='mb-9'>
        <div className='px-6'>
          <InfoBanner>
            <p
                className='fs-7 mb-0'>{t('On this page, you can create, view, and edit customer records for the masters.')}
            </p>
          </InfoBanner>

          <TableHeader
              title={t('Logbook Journal')}
              colSize={8}
              btnColSize={4}
              buttons={
                <Row
                    className='gx-3 justify-content-lg-end mt-3 mt-lg-0'>
                  <Col
                      xs={7}
                      xxl={6}>
                    <Button
                        variant='green'
                        className='w-100 text-white'
                        onClick={handleCreateRecord}
                    >
                      <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                      {t('Add record')}
                    </Button>
                  </Col>
                  {!isEmployeeRole ?
                    <Col xs={5} xxl={4}>
                      <FiltersButton filter={filters} showFilters={showFilters} onClick={toggleFilters}/>
                    </Col> : null
                  }
                </Row>
              }
              showFilters={showFilters}
          >
            <CalendarFilterWrapper setFilters={setFilters} setNotificationOfMissingData={setNotificationOfMissingData} />
          </TableHeader>
        </div>
        <CalendarWrapper
          filters={filters}
          setNotificationOfMissingData={setNotificationOfMissingData}
          notificationOfMissingData={notificationOfMissingData}
        />
        {isErrorModalOpen ?
            <ErrorModalComponent
                i18n={i18next}
                onClose={() => {
                  setIsErrorModalOpen(null)
                }}
                isOpen={!!isErrorModalOpen}
                currentError={isErrorModalOpen}
            /> : null
        }
      </Row>

      <NotificationsModal show={checkModalShow} handleClose={closeCheckModal} branchData={currentBranch} />

      { showToast &&  <ToastComponent show={showToast} setShow={setShowToast}  text={toastText}/>}
    </>
  );
};

export default Logbook;