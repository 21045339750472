import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {InfoBanner, ReactSelect, TableHeader} from "@services/ui-components";
import {ServicePick} from "../../ui-components/CategotyPick/ServicePick";
import {Button, Col, Container, Row} from "react-bootstrap";
import DateView from '@services/ui-components/src/DatePicker/DatePicker';
import {StatusPicker} from "./StatusPicker/StatusPicker";
import {
    ReactSelectInterface,
    recordFormValueType,
    StatusPickerInner,
    ValidationRecordError
} from "../../../interfaces/interfaces";
import {ServiceCategoryNewProxy, ServiceProxy} from '@me-team/host/main/graphql/types';
import {
    useCreateRecordMutation,
    useGetBookingInfoQuery,
    useGetClientInfoForRecordQuery,
    useGetDatesForRecordQuery,
    useGetDatesTimesForRecordQuery,
    useGetEmployeesForRecordQuery,
    useGetServiceCategoryForRecordQuery,
    useGetStatusListForRecordQuery,
    useUpdateRecordMutation
} from '@me-team/host/booking/addRecord/graphql/addRecord.hooks';
import {
    useGetModuleListForRecordQuery,
    useGetTechnologicalMapsServiceQuery,
    useGetUserRoleForRecordQuery,
} from '@me-team/host/main/addRecord/graphql/addRecord.hooks';
import {AddRecordService} from '../../../services/addRecord/AddRecordService';
import {DateTime} from 'luxon';
import {apolloClientBooking} from '@me-team/host/src/apollo/apollo-client';
import {ApolloError, useReactiveVar} from "@apollo/client";
import {currentBranchIdVar, currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import {ErrorMessage, Field, FieldProps, Form, Formik} from "formik";
import {BookingDate, BookingTime, EmployeeBookingProxy} from "@me-team/host/booking/graphql/types";
import {TimePickerWrapper} from "./TimePickerWrapper/TimePickerWrapper";
import {NotificationSelect} from "./NotificationSelect/NotificationSelect";
import {ClearValueBtn} from "./ClearValueBtn/ClearValueBtn";
import {employeeWorkStatus} from "../../../utils/enums";
import {Debounce} from "../../../services/Debounce";
import {PhoneInput} from "react-international-phone";
import {useNavigate, useParams} from "react-router-dom";

export const AddRecord: React.FC = () => {
    const {t: translate} = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const [serviceCategoryList, setServiceList] = useState<ServiceCategoryNewProxy[]>(null);
    const [companyCurrencySign, setCompanyCurrencySign] = useState<string>(null);
    const [statusList, setStatusList] = useState<StatusPickerInner[]>(null);
    const currentBranchId: string = useReactiveVar(currentBranchIdVar)?.toString();
    const [pickedServiceId, setPickedServiceId] = useState<number>(null);
    const [technologicalMaps, setTechnologicalMaps] = useState<ReactSelectInterface[]>([]);
    const [selectedTechnologicalMaps, setSelectedTechnologicalMaps] = useState<ReactSelectInterface[]>([]);
    const [employeeList, setEmployeeList] = useState<EmployeeBookingProxy[]>(null);
    const [pickedEmployeeId, setPickedEmployeeId] = useState<number>(null);
    const [bookingDates, setBookingDates] = useState<BookingDate[]>(null);
    const [pickedDate, setPickedDate] = useState<Date>(null);
    const [pickedTime, setPickedTime] = useState<DateTime>(null);
    const [bookingTimes, setBookingTimes] = useState<BookingTime[]>(null);
    const [isWithWarehouse, setIsWithWarehouse] = useState<boolean>(null);
    const {bookingUuid} = useParams();
    const basePath = process.env.REACT_APP_POS_URL;
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState<recordFormValueType>(null);
    const timePickerDivRef = useRef<HTMLDivElement>(null);

    const {loading: loadingService} = useGetServiceCategoryForRecordQuery({
        skip: !currentBranchId,
        variables: {
            branchId: parseInt(currentBranchId),
            employeeId: pickedEmployeeId,
            dateTime: (pickedDate && pickedTime) ? DateTime.fromJSDate(pickedDate).toFormat('yyyy-MM-dd HH:mm:ss') : null,
            bookingUuid: bookingUuid ? bookingUuid : null

        },
        fetchPolicy: 'network-only',
        client: apolloClientBooking,
        onCompleted: (data) => {
            const enableServiceCategories = data.serviceCategories
                .filter(({deleted}) => !deleted)
                .map(({services, ...category}) => ({
                    ...category,
                    services: services.filter(({deleted}) => !deleted)
                }));
            if (bookingUuid) {
                setServiceList(data.serviceCategories);
            } else {
                setServiceList(enableServiceCategories);
            }
        }
    })

    const {data: statusData, loading: loadingStatus} = useGetStatusListForRecordQuery({
        fetchPolicy: 'network-only',
        client: apolloClientBooking,
    })

    const {data: moduleData, loading: loadingModule} = useGetModuleListForRecordQuery({
        skip: !currentCompanyId,
        fetchPolicy: 'network-only',
        variables: {companyId: parseInt(currentCompanyId)},
        onCompleted: (data) => {
            setCompanyCurrencySign(data.user.company.currency.currencySign);
            const WAREHOUSE_NAME = 'Warehouses'
            const isWithWarehouse = data.user.company.modules.some((module) => module.name === WAREHOUSE_NAME);
            setIsWithWarehouse(isWithWarehouse)
        }
    });


    const {refetch: refetchEmployees, loading: loadingEmployees} = useGetEmployeesForRecordQuery({
        skip: !currentBranchId,
        variables: {
            branchId: currentBranchId,
            online: false,
            serviceId: pickedServiceId ? String(pickedServiceId) : null,
            dateTime: (pickedDate && pickedTime) ? DateTime.fromJSDate(pickedDate).toFormat('yyyy-MM-dd HH:mm:ss') : null,
            bookingId: bookingUuid ? bookingUuid : null
        },
        client: apolloClientBooking,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (bookingUuid) {
                setEmployeeList(data.employees)
            } else {
                const enableEmployees =
                    data.employees.filter(employee => employee.status.id !== employeeWorkStatus.FIRED)
                setEmployeeList(enableEmployees)
            }
        }
    })

    const {refetch: refetchDates, loading: loadingDates} = useGetDatesForRecordQuery({
        skip: !currentBranchId,
        variables: {
            branchId: currentBranchId,
            online: false,
            serviceId: pickedServiceId ? String(pickedServiceId) : null,
            employeeId: pickedEmployeeId ? String(pickedEmployeeId) : null,
            bookingId: bookingUuid ? bookingUuid : null
        },
        client: apolloClientBooking,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setBookingDates(data.bookingDates)
        }
    });

    let checkTimesByDate: (bookingTimes: BookingTime[], pikedDate: Date) => void;

    const {loading: loadingTimes} = useGetDatesTimesForRecordQuery({
        skip: !pickedDate || !currentBranchId,
        variables: {
            branchId: currentBranchId,
            online: false,
            serviceId: pickedServiceId ? String(pickedServiceId) : null,
            employeeId: pickedEmployeeId ? String(pickedEmployeeId) : null,
            date: DateTime.fromJSDate(pickedDate).toFormat('yyyy-MM-dd'),
            bookingId: bookingUuid ? bookingUuid : null
        },
        client: apolloClientBooking,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            checkTimesByDate(data.bookingTimes, pickedDate);
            setBookingTimes(data.bookingTimes);
        }
    });

    const {refetch: refetchClientInfo, loading: loadingClientInfo} = useGetClientInfoForRecordQuery({
        skip: true,
        variables: {branchId: currentBranchId, searchEmail: '', searchPhone: ''},
        client: apolloClientBooking,
        fetchPolicy: 'network-only',
    });


    const {
        data: technologicalMapsData,
        loading: loadingTechnologicalMaps,
        refetch: refetchTechnologicalMaps
    } = useGetTechnologicalMapsServiceQuery({
        skip: !currentCompanyId,
        variables: {companyId: parseInt(currentCompanyId)},
        onCompleted: (data) => {
            setTechnologicalMaps(data.user.company.technologicalMaps.map((technologicalMap) => {
                return {
                    label: technologicalMap.name,
                    value: technologicalMap.id
                }
            }))
        }
    });

    const {loading: loadingSelectedTechnologicalMaps} = useGetTechnologicalMapsServiceQuery({
        skip: !pickedServiceId || !currentCompanyId,
        variables: {serviceId: pickedServiceId, companyId: +currentCompanyId},
        onCompleted: (data) => {
            if (data.user.company.technologicalMaps.length !== 0) {
                const selectedMaps = data.user.company.technologicalMaps.map((technologicalMap) => {
                    return {
                        label: technologicalMap.name,
                        value: technologicalMap.id,
                    };
                });

                setSelectedTechnologicalMaps(selectedMaps);

                const filteredMaps = technologicalMaps
                    .map((technologicalMap) => {
                        return {
                            label: technologicalMap.label,
                            value: technologicalMap.value,
                        };
                    })
                    .filter((map) =>
                        !selectedMaps.some((selectedMap) => selectedMap.value === map.value)
                    );
                setTechnologicalMaps(filteredMaps);
            } else {
                setSelectedTechnologicalMaps([]);
                refetchTechnologicalMaps().then((techCardsData) => {
                    setTechnologicalMaps(techCardsData.data.user.company.technologicalMaps.map((technologicalMap) => {
                        return {
                            label: technologicalMap.name,
                            value: technologicalMap.id
                        }
                    }))
                })
            }
        },
    });

    const {data: bookingData} = useGetBookingInfoQuery({
        skip: !bookingUuid,
        variables: {uuid: bookingUuid, online: false},
        fetchPolicy: 'network-only',
        client: apolloClientBooking,
        onCompleted: (data) => {
            const selectedRecordMaps = data.booking.technologicalMaps.map((technologicalMap) => {
                return {
                    label: technologicalMap.name,
                    value: technologicalMap.id,
                };
            })
            setSelectedTechnologicalMaps(selectedRecordMaps);
            const filteredMaps = technologicalMapsData.user.company.technologicalMaps
                .map((technologicalMap) => {
                    return {
                        label: technologicalMap.name,
                        value: technologicalMap.id,
                    };
                })
                .filter((map) =>
                    !selectedRecordMaps.some((selectedMap) => selectedMap.value === map.value)
                );
            setTechnologicalMaps(filteredMaps);
            const combinedDateTime = DateTime.fromFormat(`${data.booking.date} ${data.booking.time}`, 'yyyy-MM-dd HH:mm:ss');
            setPickedDate(combinedDateTime.toJSDate());
            setPickedTime(DateTime.fromFormat(data.booking.time, 'HH:mm:ss'));
            setPickedEmployeeId(data.booking.employee.id);
        }
    })

    const {data : userRoleData, loading : loadingUserRoleData} = useGetUserRoleForRecordQuery();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const dateParam = params.get('date');
        const employeeIdParam = params.get('employeeId');
        const timeParam = params.get('time');

        let dateTime: DateTime = null;

        if (dateParam && timeParam) {
            dateTime = DateTime.fromFormat(`${dateParam} ${timeParam}`, 'yyyy-MM-dd HH:mm');
            setPickedDate(dateTime.toJSDate());
            setPickedTime(dateTime);
        }

        if (statusData && userRoleData) {
            if (AddRecordService.selectedDefaultEmployee(userRoleData.user, employeeIdParam)) {
                setPickedEmployeeId(AddRecordService.selectedDefaultEmployee(userRoleData.user, employeeIdParam));
            }
            const initialValuesFromUrl: recordFormValueType = {
                service: {categoryId: null, serviceId: null, duration: null},
                technologicalMap: [],
                employeeId: AddRecordService.selectedDefaultEmployee(userRoleData.user, employeeIdParam),
                price: 0,
                pickedDate: dateTime ? dateTime.toJSDate() : null,
                startTime: timeParam ? DateTime.fromFormat(timeParam, 'HH:mm') : null,
                endTime: null,
                status: {
                    isSelected: false,
                    statusId:
                    AddRecordService.statusToShow(statusData.bookingStatus,
                        dateTime ? dateTime : null,
                        isWithWarehouse).find((status) => status.useByDefault).id
                },
                phone: '',
                email: '',
                clientName: '',
                clientMiddleName: '',
                clientLastName: '',
                notes: '',
                notification: {isUserPick: false, date: null}
            };

            if (!bookingData?.booking) {
                setInitialValues(initialValuesFromUrl);
            } else {
                setInitialValues(AddRecordService.generateInitialValuesFromBooking(bookingData.booking));
            }
        }
    }, [bookingUuid, bookingData, statusData, userRoleData]);


    const [createRecord] = useCreateRecordMutation({
        client: apolloClientBooking,
        fetchPolicy: 'network-only',
    });

    const [updateRecord] = useUpdateRecordMutation({
        client: apolloClientBooking,
        fetchPolicy: 'network-only'
    })

    useEffect(() => {

        //TODO change default date to null maybe?
        if (moduleData && statusData) {
            const currentDate = pickedDate ? DateTime.fromJSDate(pickedDate) :
                DateTime.now().plus({second: 10});
            setStatusList(AddRecordService.statusToShow(statusData.bookingStatus, currentDate, isWithWarehouse));
        }

    }, [statusData, moduleData, pickedDate, isWithWarehouse]);

    const isDisabledDate = (date: Date): boolean => {
        if (!bookingDates) {
            return true
        }

        if (bookingDates.length === 0) {
            return true;
        }

        return bookingDates.some(({start, end}) => {
            const startDate = new Date(start).setHours(0, 0, 0);
            const endDate = new Date(end).setHours(0, 0, 0);

            return date.getTime() >= startDate && date.getTime() <= endDate;
        });
    };


    const handleSubmit = (values: recordFormValueType, {setSubmitting, setValues, setErrors}: {
        setSubmitting: (isSubmitting: boolean) => void;
        setFieldError: (field: string, message: string) => void;
        setValues: (values: recordFormValueType) => void;
        setErrors: (errors: { [field: string]: string }) => void;
    }) => {

        const serverErrorHandling = async (error: ApolloError) => {
            const currentError: ValidationRecordError = AddRecordService.submitRecordErrors(error);

            if (currentError.date) {
                setValues({
                    ...values,
                    pickedDate: null,
                    startTime: null,
                    endTime: null
                });
                setPickedDate(null);
                setPickedTime(null);
                const errorMessage: string = translate(`${currentError.date[0].message}`);

                try {
                    const refetchResult = await refetchDates();
                    setBookingDates(refetchResult.data.bookingDates);
                } catch (refetchError) {
                    const apolloError = refetchError as ApolloError;
                    console.error('Refetch error', apolloError);
                }
                setErrors({
                    pickedDate: errorMessage,
                    startTime: '',
                });

            }

            if (currentError.employee) {
                setValues({
                    ...values,
                    employeeId: null
                });
                setPickedEmployeeId(null);
                const errorMessage: string = translate(`${currentError.employee[0].message}`);
                try {
                    const refetchResult = await refetchEmployees();
                    setEmployeeList(refetchResult.data.employees)
                } catch (refetchError) {
                    const apolloError = refetchError as ApolloError;

                }
                setErrors({
                    employee: errorMessage
                });
            }

            if (currentError.service) {
                setValues({
                    ...values,
                    service: {categoryId: null, serviceId: null, duration: null},
                    technologicalMap: []
                });

                const errorMessage: string = translate(`${currentError.service[0].message}`);

                setPickedServiceId(null);
                setSelectedTechnologicalMaps([]);

                try {
                    const refetchResult = await refetchTechnologicalMaps();
                    setTechnologicalMaps(refetchResult.data.user.company.technologicalMaps.map((technologicalMap) => {
                        return {
                            label: technologicalMap.name,
                            value: technologicalMap.id
                        }
                    }))
                } catch (refetchError) {
                    const apolloError = refetchError as ApolloError;

                }

                setErrors({
                    service: errorMessage
                });

            }
        }

        const recordInput = AddRecordService.generateRecordInput(values, parseInt(currentBranchId), selectedTechnologicalMaps, bookingUuid);
        if (bookingUuid) {
            updateRecord({
                variables: {uuid: bookingUuid, branchId : parseInt(currentBranchId),  input: recordInput},
                onCompleted: (data) => {
                    navigate(`/logbook/journal/${currentBranchId}`, {
                        state: {
                            text: translate('Saved'),
                            toast: true,
                            recordId: data.updateAdminBooking.id
                        }
                    })
                    setSubmitting(false);
                },
                onError: async (error) => {
                    await serverErrorHandling(error);
                    setSubmitting(false);
                }
            })
        } else {
            createRecord(
                {
                    variables: {branchId: parseInt(currentBranchId), input: recordInput},
                    onCompleted: (data) => {
                        navigate(`/logbook/journal/${currentBranchId}`, {
                            state: {
                                text: translate('Created'),
                                toast: true,
                                recordId: data.createAdminBooking.id
                            }
                        })
                        setSubmitting(false);
                    },
                    onError: async (error) => {
                        await serverErrorHandling(error)
                        setSubmitting(false);
                    }
                }
            )
        }
    }

    const loading = loadingStatus ||
        loadingClientInfo || loadingTimes ||
        loadingService || loadingDates ||
        loadingEmployees || loadingTechnologicalMaps ||
        loadingSelectedTechnologicalMaps || loadingModule || loadingUserRoleData

    return (
        <>
            {loading &&
                <div className='d-flex align-items-center justify-content-center position-fixed top-50 end-50 z-3'>
                    <div className='preloader__spinner'></div>
                </div>}

            <Formik
                validationSchema={AddRecordService.recordValidation(translate)}
                initialValues={initialValues}
                enableReinitialize
                validateOnChange={true}
                validateOnBlur={false}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
                {({
                      values,
                      setFieldValue,
                      isSubmitting,
                      errors,
                      touched,
                      setValues,
                      setFieldError,
                      setFieldTouched
                  }) => {


                    checkTimesByDate = (bookingTimes: BookingTime[], pickedDate: Date) => {
                        setPickedTime(values.startTime);

                        if (values.startTime && bookingTimes.length !== 0) {
                            const isValid = AddRecordService.checkTimesRangeInCurrentDate(
                                pickedDate,
                                bookingTimes,
                                values.startTime
                            );

                            if (!isValid) {
                                setPickedTime(null);
                                setFieldTouched('startTime', true, false);
                                setValues({
                                    ...values,
                                    startTime: null,
                                    endTime: null
                                }, false);

                                setFieldError('startTime', translate('Select an available time'));
                            }
                        }
                    };


                    const onServiceClearValue = async () => {
                        setPickedServiceId(null);

                        const updatedValues: recordFormValueType = {
                            ...values,
                            service: {categoryId: null, serviceId: null, duration: null},
                            price: 0,
                            technologicalMap: [],
                        };

                        try {
                            const refetchResult = await refetchTechnologicalMaps();
                            setTechnologicalMaps(refetchResult.data.user.company.technologicalMaps.map((technologicalMap) => {
                                return {
                                    label: technologicalMap.name,
                                    value: technologicalMap.id
                                }
                            }))
                        } catch (refetchError) {
                            const apolloError = refetchError as ApolloError;

                        }

                        setSelectedTechnologicalMaps([]);
                        setValues(updatedValues);
                    };

                    const onEmployeeClearValue = () => {
                        setPickedEmployeeId(null);

                        const updatedValues: recordFormValueType = {
                            ...values,
                            employeeId: null,
                            service: {...values.service, duration: null},
                            endTime: null,
                            price: 0,
                        };

                        setValues(updatedValues);
                    };


                    const onDateTimeClearValues = () => {
                        setPickedDate(null);
                        setPickedTime(null);

                        const updatedValues: recordFormValueType = {
                            ...values,
                            pickedDate: null,
                            startTime: null,
                            endTime: null,
                        };

                        setValues(updatedValues);
                    };

                    const onServicePickHandler = (serviceId: number, isPicked: boolean, categoryId: number) => {
                        if (isPicked) {
                            setPickedServiceId(serviceId);

                            const currentCategory: ServiceCategoryNewProxy = serviceCategoryList.find((category) => category.id === categoryId);
                            const currentService: ServiceProxy = currentCategory.services.find((service) => service.id === serviceId);
                            const updatedEndTime = values.startTime
                                ? AddRecordService.calcEndTime(values.startTime,
                                    values.employeeId ? currentService.employeeServiceRelations[0].duration : currentService.duration)
                                : null;

                            const updatedValues: recordFormValueType = {
                                ...values,
                                service: {
                                    categoryId: categoryId,
                                    serviceId: serviceId,
                                    duration: values.employeeId ? currentService.employeeServiceRelations[0].duration : currentService.duration,
                                },
                                price: values.employeeId ? currentService.employeeServiceRelations[0].price : values.price,
                                endTime: updatedEndTime,
                            };

                            setValues(updatedValues);
                        } else {
                            onServiceClearValue();
                        }
                    };
                    const deleteSelectedTechnologicalMaps = (technologicalMap: ReactSelectInterface) => {
                        setSelectedTechnologicalMaps(
                            selectedTechnologicalMaps.filter(
                                (map) => map.value !== technologicalMap.value
                            )
                        );

                        setFieldValue('technologicalMap',
                            values.technologicalMap.filter(
                                (technologicalMapItem) => technologicalMapItem.value !== technologicalMap.value
                            )
                        );

                        setTechnologicalMaps(
                            [...technologicalMaps, technologicalMap].sort((a, b) =>
                                a.label.localeCompare(b.label)
                            )
                        );
                    }

                    const onTimePickHandler = (newDateTime: DateTime) => {
                        const updatedPickedDate = new Date(pickedDate);
                        const clearSecond = newDateTime.set({second: 0});
                        updatedPickedDate.setHours(clearSecond.hour, clearSecond.minute, 0, 0);

                        const updatedEndTime = values.service.duration
                            ? AddRecordService.calcEndTime(clearSecond, values.service.duration)
                            : null;

                        const updateNotification: ReactSelectInterface =
                            AddRecordService.notificationValueCalc(
                                values.notification,
                                DateTime.fromJSDate(updatedPickedDate),
                                translate);

                        const notificationToString =
                            AddRecordService.notificationValueToString(updateNotification, DateTime.fromJSDate(updatedPickedDate));

                        const status: StatusPickerInner =
                            AddRecordService.statusService(
                                values.status,
                                AddRecordService.statusToShow(statusData.bookingStatus,
                                    DateTime.fromJSDate(updatedPickedDate),
                                    isWithWarehouse)
                            );

                        const updatedValues: recordFormValueType = {
                            ...values,
                            pickedDate: updatedPickedDate,
                            status: {...values.status, statusId: status.id},
                            startTime: clearSecond,
                            endTime: updatedEndTime,
                            notification: {
                                ...values.notification,
                                date: notificationToString
                            }
                        };

                        setValues(updatedValues);
                        setPickedTime(clearSecond);
                        setPickedDate(updatedPickedDate);
                    };

                    const debouncedSendQuery = Debounce((field: 'phone' | 'email', value: string) => {
                        if (!value) return;
                        const phone: string = (field === 'phone' && value) ? value : null;
                        const email: string = (field === 'email' && value) ? value : null;
                        refetchClientInfo({
                            branchId: currentBranchId,
                            searchPhone: phone,
                            searchEmail: email
                        }).then((response) => {
                            if (response.data.client) {
                                if (field === 'email') {
                                    const clientInfo = {
                                        clientName: response.data.client.name,
                                        clientMiddleName: response.data.client.surname ? response.data.client.surname : '',
                                        clientLastName: response.data.client.patronymic ? response.data.client.patronymic : '',
                                        clientPhone: response.data.client.phone ? response.data.client.phone : ''
                                    }

                                    setValues({
                                        ...values,
                                        email: value,
                                        phone: clientInfo.clientPhone,
                                        clientName: clientInfo.clientName,
                                        clientMiddleName: clientInfo.clientMiddleName,
                                        clientLastName: clientInfo.clientLastName
                                    })
                                }

                                if (field === 'phone') {
                                    const clientInfo = {
                                        clientName: response.data.client.name,
                                        clientMiddleName: response.data.client.surname ? response.data.client.surname : '',
                                        clientLastName: response.data.client.patronymic ? response.data.client.patronymic : '',
                                        clientEmail: response.data.client.email ? response.data.client.email : '',
                                    }

                                    setValues({
                                        ...values,
                                        email: clientInfo.clientEmail,
                                        phone: value,
                                        clientName: clientInfo.clientName,
                                        clientMiddleName: clientInfo.clientMiddleName,
                                        clientLastName: clientInfo.clientLastName
                                    })
                                }
                            }

                        }).catch((error) => {
                            console.error(`Error fetching data for ${field}:`, error);
                        });
                    }, 1000);

                    return (
                        (serviceCategoryList && bookingDates && technologicalMaps && employeeList && statusList) && (
                            <>
                                <InfoBanner>
                                    <p className='fs-7 mb-0'>{translate('On this page you can create a new booking. Required fields are marked with an asterisk')} (<span
                                        className="text-danger">*</span>)</p>
                                </InfoBanner>

                                <TableHeader
                                    title={bookingUuid ? translate('Edit booking') : translate('Add new booking')}
                                    paths={[
                                        {
                                            path: `/logbook/journal/${currentBranchId}`,
                                            label: translate('Logbook Journal')
                                        },
                                        bookingUuid ? {
                                                path: `/logbook/edit-record/${bookingUuid}`,
                                                label: translate('Edit booking')
                                            } :
                                            {path: `/logbook/add-record`, label: translate('Add new booking')},
                                    ]}
                                />
                                <Form>
                                    <div className={'col-lg-9 pb-3'}>
                                        <section>
                                            <h4 className={'mb-3'}>{translate('Service')}</h4>
                                            <Container className={'px-0 pe-lg-6 ms-0'}>
                                                <Row>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-service-field"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Service name')}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                    </Col>
                                                    <Col xl={10} className={'position-relative'}>
                                                        <ServicePick
                                                            serviceCategoryList={serviceCategoryList}
                                                            companyCurrencySign={companyCurrencySign}
                                                            selectedCategoryId={values.service.categoryId}
                                                            selectedServiceId={values.service.serviceId}
                                                            onServicePick={onServicePickHandler}
                                                            isDisabled={pickedDate && !pickedTime && !pickedServiceId}
                                                            employeeId={pickedEmployeeId}
                                                            name={'service'}
                                                            hasError={!!(touched.service?.serviceId && errors.service?.serviceId)}
                                                            id={'createNewBooking-service-field'}
                                                        />
                                                        {(values.service.categoryId && values.service.serviceId) &&
                                                            <ClearValueBtn onClearValue={onServiceClearValue}/>}
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <ErrorMessage name="service.serviceId">{msg => <p
                                                            className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </Col>
                                                </Row>
                                                {isWithWarehouse && <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-technologicalMap-field"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Technological Map')}
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <ReactSelect
                                                            options={technologicalMaps}
                                                            value={null}
                                                            onChange={(value: ReactSelectInterface) => {
                                                                setSelectedTechnologicalMaps([...selectedTechnologicalMaps, value]);
                                                                setTechnologicalMaps(
                                                                    technologicalMaps.filter(
                                                                        (technologicalMap: ReactSelectInterface) => technologicalMap.value !== value.value)
                                                                );
                                                            }}
                                                            placeholder={translate('Select Technological Map')}
                                                            isSearchable
                                                            name={'technologicalMap'}
                                                            id={'createNewBooking-technologicalMap-field'}
                                                            disabled={!(!!values.service.serviceId)}
                                                        />
                                                    </Col>

                                                    {selectedTechnologicalMaps.length !== 0 && (
                                                        <Col xl={{span: 10, offset: 2}}>
                                                            {selectedTechnologicalMaps.map((selectedTechnologicalMap) => {
                                                                return <div
                                                                    className={'ps-2 ms-1 mt-3 d-flex align-items-center justify-content-between'}
                                                                    key={selectedTechnologicalMap.value}>
                                                                    {selectedTechnologicalMap.label}
                                                                    <button type="button"
                                                                            className=" text-dark ms-2  px-3 border square-btn btn btn-outline-gray"
                                                                            onClick={() => {
                                                                                deleteSelectedTechnologicalMaps(selectedTechnologicalMap)
                                                                            }}>
                                                                        <i className="bi bi-dash-lg"></i>
                                                                    </button>
                                                                </div>
                                                            })}
                                                        </Col>)}
                                                </Row>}
                                                <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-employeeSelect-field"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Master')}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                    </Col>
                                                    <Col xl={10} className={'position-relative'}>
                                                        <ReactSelect
                                                            options={employeeList.map((employee) => {
                                                                return {
                                                                    label: `${employee.surname} ${employee.name}`,
                                                                    value: employee.id
                                                                }
                                                            })}
                                                            value={values.employeeId ? AddRecordService.getEmployeeForSelect(values.employeeId, employeeList) : null}
                                                            onChange={(value: ReactSelectInterface) => {
                                                                const currentEmployee = employeeList.find((employee) => employee.id === value.value);
                                                                const updatedValues = {
                                                                    ...values,
                                                                    employeeId: value.value,
                                                                    price: values.service.serviceId ? currentEmployee.employeeServiceRelation.price : values.price,
                                                                    service: values.service.serviceId ? {
                                                                        ...values.service,
                                                                        duration: currentEmployee.employeeServiceRelation.duration
                                                                    } : values.service,
                                                                    endTime: values.startTime && values.service.serviceId
                                                                        ? AddRecordService.calcEndTime(values.startTime, currentEmployee.employeeServiceRelation.duration)
                                                                        : null
                                                                };

                                                                setValues(updatedValues);
                                                                setPickedEmployeeId(value.value)

                                                            }}
                                                            placeholder={translate('Choose Master')}
                                                            isSearchable
                                                            name={'createNewBooking-employeeSelect-field'}
                                                            disabled={pickedDate && !pickedTime && !pickedEmployeeId}
                                                        />
                                                        {(values.employeeId) &&
                                                            <ClearValueBtn onClearValue={onEmployeeClearValue}/>}
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <ErrorMessage name="employeeId">{msg => <p
                                                            className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label className="text-dark fs-7 fw-bold">
                                                            {translate('Price')}
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <p className={'mb-0 fs-7'}>{moduleData.user.company.currency.currencySign}<span
                                                            className={`ms-2 ${values.price === 0 ? 'text-secondary' : ''}`}>
                                                            {values.price}
                                                        </span>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </section>
                                        <section className={'my-5'}>
                                            <h4 className={'mb-3'}>{translate('dateAndTime')}</h4>
                                            <Container className={'px-0 pe-lg-6 ms-0'}>
                                                <Row>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-recordDate-field"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Date of Record')}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <div className='position-relative'>
                                                            <DateView
                                                                selected={pickedDate}
                                                                id={'createNewBooking-recordDate-field'}
                                                                className={`form-control  w-100 ${!!(touched.pickedDate && errors.pickedDate) ? 'border-danger' : ''}`}
                                                                placeholderText={translate('dd.mm.yyyy')}
                                                                onChange={(date) => {
                                                                    const updateNotification =
                                                                        AddRecordService.notificationValueCalc(
                                                                            values.notification, DateTime.fromJSDate(date), translate
                                                                        );
                                                                    const notificationToString = AddRecordService.notificationValueToString(updateNotification, DateTime.fromJSDate(date));
                                                                    const status: StatusPickerInner =
                                                                        AddRecordService.statusService(
                                                                            values.status,
                                                                            AddRecordService.statusToShow(statusData.bookingStatus,
                                                                                DateTime.fromJSDate(date),
                                                                                isWithWarehouse)
                                                                        );


                                                                    const updatedValues: recordFormValueType = {
                                                                        ...values,
                                                                        pickedDate: date,
                                                                        status: {...values.status, statusId: status.id},
                                                                        notification: {
                                                                            ...values.notification,
                                                                            date: notificationToString
                                                                        }
                                                                    };
                                                                    setValues(updatedValues);
                                                                    setPickedTime(null)
                                                                    setPickedDate(date);
                                                                }}
                                                                wrapperClassName='w-100 position-relative'
                                                                dateFormat='dd.MM.yyyy'
                                                                filterDate={isDisabledDate}
                                                            />
                                                            <label htmlFor="createNewBooking-recordDate-field"
                                                                   className={'position-absolute d-flex top-50 translate-middle-y end-0 pe-2 me-1'}>
                                                                <i className='bi bi-calendar4-week d-flex'></i>
                                                            </label>
                                                            {values.pickedDate &&
                                                                <ClearValueBtn onClearValue={onDateTimeClearValues}/>}
                                                        </div>
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <ErrorMessage name="pickedDate">{msg => <p
                                                            className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-startTime-field"
                                                               onClick={() => {
                                                                   const inputElement = timePickerDivRef.current?.querySelector('input');
                                                                   inputElement?.focus();
                                                               }}
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Time of Record')}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                    </Col>
                                                    <Col xl={10} className={'time-picker-wrapper'}>
                                                        <div className={'d-flex align-items-center'}>
                                                            <div className='position-relative w-100'>
                                                                <TimePickerWrapper name={'startTime'}
                                                                                   startValue={values.startTime ? values.startTime.toFormat('HH:mm') : ''}
                                                                                   onChange={onTimePickHandler}
                                                                                   isDisabled={!pickedDate}
                                                                                   inactiveIntervals={bookingTimes}
                                                                                   step={15}
                                                                                   id={'createNewBooking-startTime-field'}
                                                                                   ref={timePickerDivRef}
                                                                />
                                                            </div>
                                                            <i className="bi bi-dash d-flex px-3"></i>
                                                            <div className='position-relative w-100'>
                                                                <TimePickerWrapper name={'endTime'}
                                                                                   startValue={values.endTime ? values.endTime.toFormat('HH:mm') : ''}
                                                                                   onChange={onTimePickHandler}
                                                                                   isDisabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <ErrorMessage name="startTime">{msg => <p
                                                            className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-recordStatus-field"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Visit Status')}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <StatusPicker
                                                            statusList={statusList}
                                                            selectedDate={DateTime.fromJSDate(pickedDate)}
                                                            value={AddRecordService.statusService(
                                                                values.status,
                                                                AddRecordService.statusToShow(statusData.bookingStatus,
                                                                    pickedDate ? DateTime.fromJSDate(pickedDate) : DateTime.now().plus({second: 10}),
                                                                    isWithWarehouse)
                                                            )}
                                                            onChange={(statusId: number) => {
                                                                setFieldValue('status', {
                                                                    isSelected: true,
                                                                    statusId: statusId
                                                                });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </section>
                                        <section className={'my-5'}>
                                            <h4 className={'mb-3'}>{translate('Information about the Client')}</h4>
                                            <Container className={'px-0 pe-lg-6 ms-0'}>
                                                <Row>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-phone-field"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Client Contacts')}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <div className='position-relative'>
                                                            <PhoneInput
                                                                name={'phone'}
                                                                className='fs-7 height-100 w-100'
                                                                placeholder={translate('Enter phone number')}
                                                                inputClassName={`form-control fs-7 rounded-1 text-truncate ${touched.phone && errors.phone ? "border-danger" : "border-secondary"}`}
                                                                inputStyle={{
                                                                    height: '50px',
                                                                    paddingLeft: "65px",
                                                                    fontSize: "16px"
                                                                }}
                                                                countrySelectorStyleProps={{
                                                                    className: 'position-absolute',
                                                                    style: {height: '50px'},
                                                                    buttonClassName: `h-100 rounded-start-1 ps-2 pe-1 ${touched.phone && errors.phone ? "border-danger" : "border-secondary"}`,
                                                                }}
                                                                value={values.phone}
                                                                onChange={(phone, meta) => {
                                                                    debouncedSendQuery('phone', phone);
                                                                    phone.slice(1) !== meta.country.dialCode ? setFieldValue('phone', phone) : ''
                                                                }}
                                                                inputProps={{
                                                                    id: 'createNewBooking-phone-field',
                                                                }}
                                                                disabled={AddRecordService.disableClientContacts(bookingUuid, values.status.statusId)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <ErrorMessage name="phone">{msg => <p
                                                            className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <div
                                                            className="text-secondary text-center my-2">
                                                            <p className="position-relative or-dash mb-0">{translate('or')}</p>
                                                        </div>
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <Field name="email">
                                                            {({
                                                                  field,
                                                                  form: {touched, errors, setFieldValue}
                                                              }: FieldProps) => (
                                                                <>
                                                                    <input
                                                                        {...field}
                                                                        placeholder={translate('Add E-mail')}
                                                                        className={`form-control rounded-1 lh-22 ${
                                                                            touched.email && errors.email ? 'border-danger' : ''
                                                                        }`}
                                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                            setFieldValue('email', e.target.value);
                                                                            debouncedSendQuery('email', e.target.value)
                                                                        }}
                                                                        disabled={AddRecordService.disableClientContacts(bookingUuid, values.status.statusId)}
                                                                    />
                                                                </>
                                                            )}
                                                        </Field>
                                                        <ErrorMessage name="email">
                                                            {msg => <p className="fs-7 p-1 text-danger m-0">{msg}</p>}
                                                        </ErrorMessage>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-clientName-field"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Client name')}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <Field name="clientName" maxLength={50}
                                                               id={'createNewBooking-clientName-field'}
                                                               placeholder={translate('Add Name')}
                                                               className={`form-control rounded-1 lh-22 ${
                                                                   touched.clientName && errors.clientName ? 'border-danger' : ''
                                                               }`}/>
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <ErrorMessage name="clientName">{msg => <p
                                                            className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-clientMiddleName-field"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Client Middle name')}
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <Field name="clientMiddleName" maxLength={50}
                                                               id={'createNewBooking-clientMiddleName-field'}
                                                               placeholder={translate('Add Middle name')}
                                                               className={`form-control rounded-1 lh-22 ${
                                                                   touched.clientMiddleName && errors.clientMiddleName ? 'border-danger' : ''
                                                               }`}/>
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <ErrorMessage name="clientMiddleName">{msg => <p
                                                            className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="createNewBooking-clientLastName-field"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Client Last name')}
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <Field name="clientLastName" maxLength={50}
                                                               id={'createNewBooking-clientLastName-field'}
                                                               placeholder={translate('Add Last name')}
                                                               className={`form-control rounded-1 lh-22 ${
                                                                   touched.clientLastName && errors.clientLastName ? 'border-danger' : ''
                                                               }`}/>
                                                    </Col>
                                                    <Col xl={{span: 10, offset: 2}}>
                                                        <ErrorMessage name="clientMiddleName">{msg => <p
                                                            className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </section>
                                        <section className={'my-5'}>
                                            <Container className={'px-0 pe-lg-6 ms-0'}>
                                                <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex'}>
                                                        <label htmlFor="createNewBooking-notes-field"
                                                               className="text-dark fs-7 fw-bold pt-2 mt-1">
                                                            {translate('Notes')}
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <Field as="textarea" name='notes' className="form-control"
                                                               id={'createNewBooking-notes-field'}
                                                               maxLength={200}
                                                               placeholder={translate('Enter notes for the booking')}

                                                        />
                                                        <ErrorMessage name="notes">{msg => <p
                                                            className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                        <span className="text-grey fs-7 mt-1 d-block">
                                                    {values.notes.length} / 200
                                                </span>
                                                    </Col>
                                                </Row>
                                                <Row className={'mt-3'}>
                                                    <Col xl={2} className={'d-flex align-items-center'}>
                                                        <label htmlFor="notification"
                                                               className="text-dark fs-7 fw-bold">
                                                            {translate('Notification')}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                    </Col>
                                                    <Col xl={10}>
                                                        <NotificationSelect
                                                            name={'notification'}
                                                            isDisabled={!(!!pickedDate && !!pickedTime)}
                                                            value={AddRecordService.notificationValueCalc(values.notification, DateTime.fromJSDate(pickedDate), translate)}
                                                            options={AddRecordService.notificationSelectOptions(DateTime.fromJSDate(pickedDate), translate)}
                                                            onChange={(selectedValue: ReactSelectInterface) => {
                                                                const valueToString = AddRecordService.notificationValueToString(selectedValue, DateTime.fromJSDate(pickedDate));
                                                                setFieldValue('notification', {
                                                                    isUserPick: true,
                                                                    date: valueToString
                                                                });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </section>
                                        <Row>
                                            <div className={'d-flex'}>
                                                <Col md={3} xl={3} className={'me-4'}>
                                                    <Button
                                                        variant='outline-primary'
                                                        className='w-100'
                                                        onClick={() => {
                                                            if (window.history.length > 1) {
                                                                window.history.back();
                                                            } else {
                                                                window.location.href = basePath;
                                                            }
                                                        }}
                                                    >
                                                        {translate('cancel')}
                                                    </Button>
                                                </Col>
                                                <Col md={3} xl={3}>
                                                    <Button
                                                        variant='primary'
                                                        type='submit'
                                                        className='w-100'
                                                        disabled={isSubmitting}
                                                    >
                                                        {bookingUuid ? translate('EditRecord') : translate('Add')}
                                                    </Button>
                                                </Col>
                                                {bookingUuid && <Col md={3} xl={3} className={'ms-auto'}>
                                                    <Button
                                                        variant='success'
                                                        type='submit'
                                                        className='w-100'
                                                        onClick={() => navigate(`/logbook/edit-record/${bookingUuid}/change-history`)}
                                                    >
                                                        {translate('History of Changes')}
                                                    </Button>
                                                </Col>}
                                            </div>
                                        </Row>
                                    </div>
                                </Form>
                            </>
                        )
                    )
                }}
            </Formik>

        </>
    )
};
